// src/components/EmployeeAllocationsManualUpload/EmployeeAllocationsManualUpload.js

import React, { useState, useMemo, useEffect } from 'react';
import {
  Segment,
  Header,
  Table,
  Button,
  Icon,
  Label,
  Checkbox,
  Dropdown,
  Input,
  Accordion,
  Popup,
  Modal,
  Search,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import useAxios from '../../axiosSetup';

// Approval Status Mapping
const APPROVAL_STATUS = {
  0: "Not Submitted",
  1: "Submitted",
  2: "Approved",
  3: "Rejected",
};

// Color Mapping for Approval Status
const APPROVAL_STATUS_COLOR = {
  0: "grey",      // Not Submitted
  1: "yellow",    // Submitted
  2: "green",     // Approved
  3: "red",       // Rejected
};

// Rejection Reasons
const rejectionReasons = [
  { key: 'hours_exceed_limit', value: 'Hours Exceed Allowed Limit', text: 'Hours Exceed Allowed Limit' },
  { key: 'incomplete_hours', value: 'Incomplete Hours Entered', text: 'Incomplete Hours Entered' },
  { key: 'unapproved_overtime', value: 'Unapproved Overtime', text: 'Unapproved Overtime' },
  { key: 'unapproved_leave', value: 'Unapproved Leave', text: 'Unapproved Leave' },
  { key: 'holiday_on_working_day', value: 'Holiday Marked on a Working Day', text: 'Holiday Marked on a Working Day' },
];

// Helper function to get ordinal suffix for dates
const getOrdinalSuffix = (date) => {
  const j = date % 10,
        k = date % 100;
  if (j === 1 && k !== 11) {
    return date + "st";
  }
  if (j === 2 && k !== 12) {
    return date + "nd";
  }
  if (j === 3 && k !== 13) {
    return date + "rd";
  }
  return date + "th";
};

const formatDateStr = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Helper function to format dates
const formatDate = (date) => {
  const day = getOrdinalSuffix(date.getDate());
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear().toString().slice(-2);
  return `${day} ${month} ${year}`;
};

// Helper function to get default comment based on action
const getDefaultComment = (action) => {
  switch (action) {
    case 'Approve':
      return 'Your timesheet for the date has been Approved.';
    case 'Reject':
      return 'Your timesheet for the date has been Rejected.';
    case 'Resubmit':
      return 'Your timesheet should be Resubmitted for the date.';
    default:
      return '';
  }
};

const EmployeeAllocationsManualUpload = ({
  employees,
  handleBulkEmployeeAction,
  handleEmployeeAction,
  timesheetComments,
  setTimesheetComments,
  selectedTimesheets,
  setSelectedTimesheets,
  filter,
  handleFilterChange,
  sortConfig,
  handleSort,
  handleTimesheetAction,
  dateRange, // Replace weekRange with dateRange
  onSave,
  onFetchTimesheetDetails,
}) => {
  const axiosInstance = useAxios(); // Initialize axiosInstance
  // Define state for Accordion
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);

  // Handler for Accordion Click
  const handleAccordionClick = (index, employeeId) => {
    // If we're opening the accordion
    if (activeAccordionIndex !== index) {
      // Check if we have unsaved changes before expanding
      if (timesheetEditMode[employeeId]) {
        setCurrentEmployeeId(employeeId);
        setShowConfirmation(true);
        return;
      }
      // Trigger timesheet details fetch when expanding
      if (onFetchTimesheetDetails) {
        onFetchTimesheetDetails(employeeId);
      }
    }
    setActiveAccordionIndex(activeAccordionIndex === index ? -1 : index);
  };

  // Define state for selected rejection reasons
  const [selectedRejectionReasons, setSelectedRejectionReasons] = useState({});

  // State for tracking worked hours per employee
  const [workedHoursInput, setWorkedHoursInput] = useState({}); // { employeeId: totalWorkedHours }

  // State to manage submission loading state
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Add this new state for tracking individual timesheet entry worked hours
  const [timesheetWorkedHours, setTimesheetWorkedHours] = useState({});

  // Add this new handler for timesheet worked hours changes
  const handleTimesheetWorkedHoursChange = (employeeId, guidId, value) => {
    setTimesheetWorkedHours(prev => ({
      ...prev,
      [employeeId]: {
        ...prev[employeeId],
        [guidId]: value
      }
    }));
  };

  // Handle comment changes in timesheet
  const handleCommentChange = (employeeId, guidId, value) => {
    setTimesheetComments((prev) => ({
      ...prev,
      [employeeId]: {
        ...prev[employeeId],
        [guidId]: value,
      },
    }));
  };

  // Handle parent checkbox toggle for an employee
  const handleParentCheckbox = (employeeId, checked) => {
    setSelectedTimesheets((prev) => {
      const updated = { ...prev };
      const employee = employees.find(emp => emp.id === employeeId);
      if (!employee) return updated;

      // Get filtered timesheet entries based on current filter
      const filteredTimesheets = employee.timesheet.filter(entry => {
        const statuses = getApprovalStatusesForFilter(filter);
        return statuses ? statuses.includes(entry.approvalStatus) : true;
      });

      if (checked) {
        // Only select timesheets that match the current filter
        updated[employeeId] = new Set(filteredTimesheets.map(entry => entry.guidId));
      } else {
        updated[employeeId] = new Set();
      }
      return updated;
    });
  };

  // Handle child checkbox toggle for a timesheet entry
  const handleChildCheckbox = (employeeId, guidId, checked) => {
    setSelectedTimesheets((prev) => {
      const updated = { ...prev };
      if (!updated[employeeId]) {
        updated[employeeId] = new Set();
      }
      if (checked) {
        updated[employeeId].add(guidId);
      } else {
        updated[employeeId].delete(guidId);
      }
      return updated;
    });
  };

  // Map filter to approvalStatus
  const getApprovalStatusesForFilter = (filter) => {
    switch (filter) {
      case 'submitted':
        return [1]; // Submitted
      case 'not-submitted':
        return [0]; // Not Submitted
      case 'approved':
        return [2]; // Approved
      case 'rejected':
        return [3]; // Rejected
      default:
        return null; // All
    }
  };

  // Sort timesheet entries based on sortConfig
  const getSortedTimesheet = (employeeId, timesheet) => {
    const config = sortConfig[employeeId];
    if (!config || !config.column) return timesheet;

    const sorted = [...timesheet].sort((a, b) => {
      let aVal = a[config.column];
      let bVal = b[config.column];

      // Handle numeric and date sorting
      if (config.column === 'date') {
        aVal = new Date(aVal);
        bVal = new Date(bVal);
      } else if (['allocatedHours', 'workedHours', 'holiday', 'leave', 'allocationPercentage'].includes(config.column)) {
        aVal = Number(aVal);
        bVal = Number(bVal);
      } else if (config.column === 'allocationStatus') {
        aVal = aVal.toLowerCase();
        bVal = bVal.toLowerCase();
      } else {
        // For string comparison
        aVal = aVal.toString().toLowerCase();
        bVal = bVal.toString().toLowerCase();
      }

      if (aVal < bVal) {
        return config.direction === 'ascending' ? -1 : 1;
      }
      if (aVal > bVal) {
        return config.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    return sorted;
  };

  // Determine available actions at the employee (parent) level
  const getAvailableActionsForEmployee = (employee) => {
    const uniqueStatuses = new Set(employee.timesheet.map(entry => entry.approvalStatus));

    if (uniqueStatuses.size !== 1) {
      // Mixed statuses
      return [];
    }

    const status = [...uniqueStatuses][0];

    switch (status) {
      case 0: // Not Submitted
        return [];
      case 1: // Submitted
        return ['Approve', 'Reject'];
      case 2: // Approved
        return ['Resubmit'];
      case 3: // Rejected
        return ['Resubmit'];
      default:
        return [];
    }
  };

  // Update the availableBulkActions logic
  const availableBulkActions = useMemo(() => {
    const actions = new Set();
    let allSubmitted = true;
    let allRejected = true;
    let allApproved = true;

    employees.forEach(employee => {
      const selected = selectedTimesheets[employee.id];
      if (selected) {
        employee.timesheet.forEach(entry => {
          if (selected.has(entry.guidId)) {
            if (entry.approvalStatus !== 1) {
              allSubmitted = false;
            }
            if (entry.approvalStatus !== 3) {
              allRejected = false;
            }
            if (entry.approvalStatus !== 2) {
              allApproved = false;
            }
          }
        });
      }
    });

    if (allSubmitted) {
      actions.add('Approve');
      actions.add('Reject');
    }
    if (allApproved) {
      actions.add('Resubmit');
    }
    // Add this condition for rejected timesheets
    if (allRejected) {
      actions.add('Resubmit');
    }

    return Array.from(actions);
  }, [employees, selectedTimesheets]);

  // Update the handleBulkAction filtering logic
  const handleBulkAction = (action) => {
    const affectedTimesheets = [];
  
    employees.forEach(employee => {
      const selected = selectedTimesheets[employee.id];
      if (selected) {
        // No need to filter by 'filter' again
        employee.timesheet.forEach(entry => {
          if (selected.has(entry.guidId)) {
            affectedTimesheets.push({ employeeId: employee.id, guidId: entry.guidId });
          }
        });
      }
    });
  
    if (affectedTimesheets.length === 0) {
      toast.error('No timesheets available for the selected action.');
      return;
    }
  
    // Call handleBulkEmployeeAction and then reload affected timesheets
    handleBulkEmployeeAction(action, affectedTimesheets).then(() => {
      // Get unique employee IDs from affected timesheets
      const affectedEmployeeIds = [...new Set(affectedTimesheets.map(t => t.employeeId))];
      
      // Reload timesheet details for each affected employee if their accordion is expanded
      affectedEmployeeIds.forEach(employeeId => {
        const employeeIndex = employees.findIndex(emp => emp.id === employeeId);
        if (activeAccordionIndex === employeeIndex) {
          onFetchTimesheetDetails(employeeId);
        }
      });
    });
  };
  

  // Handle saving all worked hours (parent edit)
  const handleSaveAllWorkedHours = async () => {
    if (!isEditMode) return;

    // Gather data for each employee with changes
    const payloads = employees
      .filter(employee => workedHoursInput[employee.id] !== undefined)
      .map(employee => ({
        startDate: formatDateStr(dateRange.startDate),
        endDate: formatDateStr(dateRange.endDate),
        allocationId: employee.allocationId,
        employeeId: employee.employeeId,
        totalWorkedHours: workedHoursInput[employee.id]
      }));

    if (payloads.length === 0) {
      toast.warning('No changes to save');
      return;
    }

    setIsSubmitting(true);

    try {
      // Make API calls for each employee
      const requests = payloads.map(payload =>
        axiosInstance.post('/api/total-hours-manual', payload)
      );

      const responses = await Promise.all(requests);

      // Check responses
      const success = responses.every(response => response.data.updated === true);

      if (success) {
        toast.success('All timesheets updated successfully.');
        if (onSave) {
          onSave();
        }
      } else {
        toast.error('Some timesheets failed to update.');
      }

      // Reset edit mode and related states
      setIsEditMode(false);
      setWorkedHoursInput({});
      setTimesheetWorkedHours({});
      setHolidayStatus({});
      setLeaveStatus({});
    } catch (error) {
      console.error('Error saving timesheets:', error);
      toast.error(`Error saving timesheets: ${error.response?.data?.error || error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add these new states at the component level
  const [holidayStatus, setHolidayStatus] = useState({});
  const [leaveStatus, setLeaveStatus] = useState({});

  // Add these new handlers
  const handleHolidayChange = (employeeId, guidId, checked) => {
    setHolidayStatus(prev => ({
      ...prev,
      [employeeId]: {
        ...prev[employeeId],
        [guidId]: checked
      }
    }));

    // If holiday is checked, ensure leave is unchecked
    if (checked) {
      setLeaveStatus(prev => ({
        ...prev,
        [employeeId]: {
          ...prev[employeeId],
          [guidId]: false
        }
      }));
    }
  };

  const handleLeaveChange = (employeeId, guidId, checked) => {
    setLeaveStatus(prev => ({
      ...prev,
      [employeeId]: {
        ...prev[employeeId],
        [guidId]: checked
      }
    }));

    // If leave is checked, ensure holiday is unchecked
    if (checked) {
      setHolidayStatus(prev => ({
        ...prev,
        [employeeId]: {
          ...prev[employeeId],
          [guidId]: false
        }
      }));
    }
  };

  // Add a new state for edit mode
  const [isEditMode, setIsEditMode] = useState(false);

  // Add new state for input suggestions
  const [showSuggestion, setShowSuggestion] = useState({});

  // Add new state for tracking timesheet-level edit mode
  const [timesheetEditMode, setTimesheetEditMode] = useState({});

  // New state for managing the confirmation dialog
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentEmployeeId, setCurrentEmployeeId] = useState(null);

  // Handler for closing the accordion with confirmation
  const handleAccordionClose = (index, employeeId) => {
    if (timesheetEditMode[employeeId]) {
      setCurrentEmployeeId(employeeId);
      setShowConfirmation(true);
    } else {
      setActiveAccordionIndex(activeAccordionIndex === index ? -1 : index);
    }
  };

  // Handler for confirmation dialog actions
  const handleConfirmationAction = (action) => {
    if (action === 'save') {
      handleSaveTimesheetChanges(currentEmployeeId);
    }
    setTimesheetEditMode(prev => ({
      ...prev,
      [currentEmployeeId]: false
    }));
    setShowConfirmation(false);
  };

  // Handler for saving timesheet changes for a specific employee
  const handleSaveTimesheetChanges = async (employeeId) => {
    const employee = employees.find(emp => emp.id === employeeId);
    if (!employee) {
      toast.error('Employee not found.');
      return;
    }
  
    const allocationId = employee.allocationId;
    if (!allocationId) {
      toast.error('Allocation ID not found for the employee.');
      return;
    }
  
    // Prepare entries to be updated
    const entriesToUpdate = employee.timesheet.map(entry => {
      const editedWorkedHours = timesheetWorkedHours[employeeId]?.[entry.guidId];
      const isHoliday = holidayStatus[employeeId]?.[entry.guidId] || false;
      const isLeave = leaveStatus[employeeId]?.[entry.guidId] || false;
  
      let timesheetHours;
      if (isHoliday) {
        timesheetHours = 0;
      } else if (isLeave) {
        timesheetHours = editedWorkedHours !== undefined ? editedWorkedHours : entry.workedHours;
      } else {
        timesheetHours = editedWorkedHours !== undefined ? editedWorkedHours : entry.workedHours;
      }
  
      return {
        date: formatDateStr(entry.date),
        holidayCheck: isHoliday,
        leaveCheck: isLeave,
        timesheetHours: timesheetHours
      };
    });
  
    // Validate entries
    const validationErrors = entriesToUpdate.filter(entry => 
      (entry.holidayCheck && entry.leaveCheck) || 
      (!entry.holidayCheck && !entry.leaveCheck && (entry.timesheetHours === undefined || entry.timesheetHours === null))
    );
  
    if (validationErrors.length > 0) {
      toast.error('Invalid entry configuration: Each entry must have either holiday, leave, or timesheet hours specified, but not both holiday and leave.');
      return;
    }
  
    // Additional validation: Ensure workedHours are provided when leave is checked
    const missingWorkedHoursForLeave = entriesToUpdate.filter(entry => 
      entry.leaveCheck && (entry.timesheetHours === undefined || entry.timesheetHours === null)
    );
  
    if (missingWorkedHoursForLeave.length > 0) {
      toast.error('Please input Worked Hours for entries marked as Leave.');
      return;
    }
  
    // Prepare the PUT request
    const payload = {
      entries: entriesToUpdate
    };
  
    setIsSubmitting(true);
  
    try {
      const response = await axiosInstance.put(`/api/manager-timesheet-day-entry/${allocationId}`, payload);
  
      if (response.status === 200) {
        toast.success('Timesheets updated successfully.');
        // Optionally, refetch employee data or update local state to reflect changes
        if (onSave) {
          onSave();
        }
        // Reset edit modes and temporary states
        setTimesheetEditMode(prev => ({
          ...prev,
          [employeeId]: false
        }));
        setTimesheetWorkedHours(prev => ({
          ...prev,
          [employeeId]: {}
        }));
        setHolidayStatus(prev => ({
          ...prev,
          [employeeId]: {}
        }));
        setLeaveStatus(prev => ({
          ...prev,
          [employeeId]: {}
        }));
        // Close the accordion
        setActiveAccordionIndex(-1);
      } else {
        throw new Error(response.data.error || 'Failed to update timesheets.');
      }
    } catch (error) {
      console.error('Error updating timesheets:', error);
      toast.error(`Error updating timesheets: ${error.response?.data?.error || error.message || 'Failed to update timesheets.'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleParentEditMode = () => {
    setWorkedHoursInput((prev) => {
      const updated = { ...prev };
      employees.forEach((employee) => {
        // Only set suggested hours if there's no previous value or if it's 0
        if (!employee.workedHours || employee.workedHours === 0) {
          updated[employee.id] = employee.allocatedHours;
        } else {
          // Keep the existing worked hours
          updated[employee.id] = employee.workedHours;
        }
      });
      return updated;
    });
  };

  const handleChildEditMode = (employeeId) => {
    setTimesheetWorkedHours((prev) => {
      const updated = { ...prev };
      setHolidayStatus((holidayPrev) => {
        const holidayUpdated = { ...holidayPrev };

        employees.forEach((employee) => {
          if (employee.id === employeeId) {
            employee.timesheet.forEach((entry) => {
              const dayOfWeek = new Date(entry.date).getDay();

              // Initialize timesheetWorkedHours
              if (!updated[employeeId]) {
                updated[employeeId] = {};
              }

              // Convert workedHours to number for proper comparison
              const workedHours = Number(entry.workedHours);
              
              // Check if workedHours is 0, null, undefined, or 0.00
              if (workedHours === 0 || workedHours === null || workedHours === undefined || workedHours === 0.00) {
                updated[employeeId][entry.guidId] =
                  dayOfWeek === 0 || dayOfWeek === 6 ? 0 : entry.allocatedHours;
              } else {
                // Keep the existing worked hours
                updated[employeeId][entry.guidId] = workedHours;
              }

              // Initialize holidayStatus
              if (!holidayUpdated[employeeId]) {
                holidayUpdated[employeeId] = {};
              }
              // Preserve existing holiday status
              if (holidayUpdated[employeeId][entry.guidId] === undefined) {
                holidayUpdated[employeeId][entry.guidId] = entry.holiday || (dayOfWeek === 0 || dayOfWeek === 6);
              }
            });
          }
        });

        return holidayUpdated;
      });

      return updated;
    });
  };
  
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setSearchResults([]);
      return;
    }

    const lowercasedQuery = searchQuery.toLowerCase();
    const results = employees.filter(employee =>
      employee.name.toLowerCase().includes(lowercasedQuery) ||
      employee.email.toLowerCase().includes(lowercasedQuery) ||
      employee.employeeId.toLowerCase().includes(lowercasedQuery)
    ).map(employee => ({
      title: employee.name,
      description: employee.email,
      employeeId: employee.employeeId,
    }));

    setSearchResults(results);
  }, [searchQuery, employees]);

  const [selectedEmployees, setSelectedEmployees] = useState([]); // New state for selected employees

  // Transform employees data for Dropdown options
  const employeeOptions = useMemo(() => {
    return employees.map(employee => ({
      key: employee.id,
      text: `${employee.name} (${employee.employeeId})`,
      value: employee.id,
    }));
  }, [employees]);

  // Update the finalFilteredEmployees to consider selectedEmployees
  const finalFilteredEmployees = useMemo(() => {
    let filtered = employees;
  
    if (selectedEmployees.length > 0) {
      filtered = filtered.filter(employee => selectedEmployees.includes(employee.id));
    }
  
    if (searchQuery.trim() !== '') {
      const lowercasedQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(employee =>
        employee.name.toLowerCase().includes(lowercasedQuery) ||
        employee.email.toLowerCase().includes(lowercasedQuery) ||
        employee.employeeId.toLowerCase().includes(lowercasedQuery)
      );
    }
  
    return filtered;
  }, [employees, selectedEmployees, searchQuery]);
  

  // Add a function to clear the search filter
  const clearSearchFilter = () => {
    setSearchQuery('');
    setSelectedEmployees([]);
  };

  // Helper function to determine if parent edit button should be visible
  const isParentEditVisible = () => {
    if (filter === 'not-submitted'|| filter === 'submitted' || filter === 'rejected') {
      return true;
    } else if (filter === 'all') {
      return employees.every(employee => employee.timesheet.every(entry => 
        entry.approvalStatus === 0 || entry.approvalStatus === 1 || entry.approvalStatus === 3));
    }
    return false;
  };

  // Helper function to determine if child edit button should be visible
  const isChildEditVisible = (employee) => {
    if (filter === 'not-submitted'|| filter === 'submitted' || filter === 'rejected') {
      return true;
    } else if (filter === 'all') {
      return employee.timesheet.every(entry => 
        entry.approvalStatus === 0 || entry.approvalStatus === 1 ||entry.approvalStatus === 3);
    }
    return false;
  };

  // Add these new handlers for reset and discard actions
  const handleResetParentEdit = () => {
    setWorkedHoursInput((prev) => {
      const updated = { ...prev };
      employees.forEach((employee) => {
        updated[employee.id] = employee.allocatedHours;
      });
      return updated;
    });
  };

  const handleDiscardParentEdit = () => {
    setIsEditMode(false);
    setWorkedHoursInput({});
  };

  const handleResetChildEdit = (employeeId) => {
    setTimesheetWorkedHours((prev) => {
      const updated = { ...prev };
      employees.forEach((employee) => {
        if (employee.id === employeeId) {
          employee.timesheet.forEach((entry) => {
            updated[employeeId][entry.guidId] = entry.allocatedHours;
          });
        }
      });
      return updated;
    });

    setLeaveStatus((prev) => {
      const updated = { ...prev };
      updated[employeeId] = {};
      return updated;
    });
  };

  const handleDiscardChildEdit = (employeeId) => {
    setTimesheetEditMode((prev) => ({
      ...prev,
      [employeeId]: false,
    }));
    setTimesheetWorkedHours((prev) => ({
      ...prev,
      [employeeId]: {},
    }));
    setHolidayStatus((prev) => ({
      ...prev,
      [employeeId]: {},
    }));
    setLeaveStatus((prev) => ({
      ...prev,
      [employeeId]: {},
    }));
  };

  return (
    <>
      <Segment className="employee-allocation-table" style={{ marginTop: '20px', borderRadius: '12px' }}>
        <Header as='h3'>Employee Allocations</Header>
        <Dropdown
            placeholder='Select Employees'
            fluid
            multiple
            search
            selection
            options={employeeOptions}
            value={selectedEmployees}
            onChange={(e, { value }) => setSelectedEmployees(value)}
            style={{ marginBottom: '10px' }}
          />
          
          {/* Clear All Button - only show if more than one entry is selected */}
          {selectedEmployees.length > 1 && (
            <Button onClick={clearSearchFilter} color="red" style={{ marginBottom: '5px', marginLeft: 'auto' }}>
              Clear All
            </Button>
          )}

        {/* Filter Tabs */}
        <div className="filter-tabs" style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button.Group>
            <Button
              active={filter === 'all'}
              onClick={() => handleFilterChange('all')}
            >
              All
            </Button>
            <Button
              active={filter === 'submitted'}
              onClick={() => handleFilterChange('submitted')}
            >
              Submitted
            </Button>
            <Button
              active={filter === 'not-submitted'}
              onClick={() => handleFilterChange('not-submitted')}
            >
              Not Submitted
            </Button>
            <Button
              active={filter === 'approved'}
              onClick={() => handleFilterChange('approved')}
            >
              Approved
            </Button>
            <Button
              active={filter === 'rejected'}
              onClick={() => handleFilterChange('rejected')}
            >
              Rejected
            </Button>
          </Button.Group>

          {/* Add Search Component */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
            {isParentEditVisible() && (
              <Button.Group>
                <Button
                  color={isEditMode ? 'green' : 'blue'}
                  onClick={() => {
                    if (isEditMode) {
                      handleSaveAllWorkedHours();
                    } else {
                      handleParentEditMode(); // Prefill parent edits
                      toast.info('Parent edit triggered');
                    }
                    setIsEditMode(!isEditMode);
                  }}
                  disabled={isSubmitting}
                >
                  <Icon name={isEditMode ? 'save' : 'edit'} />
                  {isEditMode ? 'Save Hours' : 'Edit Hours'}
                </Button>
                {isEditMode && (
                  <>
                    <Button
                      color="blue"
                      onClick={handleResetParentEdit}
                      disabled={isSubmitting}
                    >
                      <Icon name="undo" />
                      Reset
                    </Button>
                    <Button
                      color="grey"
                      onClick={handleDiscardParentEdit}
                      disabled={isSubmitting}
                    >
                      <Icon name="cancel" />
                      Discard
                    </Button>
                  </>
                )}
              </Button.Group>
            )}
          </div>
        </div>

        <Table celled selectable compact="very" style={{ width: '100%', borderRadius: "12px" }}>
          <Table.Header>
            <Table.Row>
              {/* Keep first column width as is */}
              <Table.HeaderCell style={{ width: '40px' }}>
                <Checkbox
                  onChange={(e, { checked }) => {
                    employees.forEach(employee => {
                      handleParentCheckbox(employee.id, checked);
                    });
                  }}
                  title="Select/Deselect All"
                  checked={employees.every(employee => selectedTimesheets[employee.id]?.size === employee.timesheet.filter(entry => {
                    const statuses = getApprovalStatusesForFilter(filter);
                    return statuses ? statuses.includes(entry.approvalStatus) : true;
                  }).length)}
                  indeterminate={
                    employees.some(employee => selectedTimesheets[employee.id]?.size > 0) &&
                    employees.some(employee => selectedTimesheets[employee.id]?.size < employee.timesheet.filter(entry => {
                      const statuses = getApprovalStatusesForFilter(filter);
                      return statuses ? statuses.includes(entry.approvalStatus) : true;
                    }).length)
                  }
                />
              </Table.HeaderCell>
              {/* Adjust other column widths */}
              <Table.HeaderCell style={{ width: '100px' }}>Employee ID</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '12%' }}>Name</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '18%' }}>Email</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '10%' }}>Role</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '10%' }}>Working Status</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '8%' }}>Allocated Hours</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '8%' }}>Worked Hours</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '8%' }}>Worked %</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '8%' }}>Holidays</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '8%' }}>Leaves</Table.HeaderCell>
              
              <Table.HeaderCell style={{ width: '10%' }}>
                <div>
                  {Object.keys(selectedTimesheets).some(employeeId =>
                    selectedTimesheets[employeeId]?.size > 0
                  ) && (
                    <>
                      {availableBulkActions.includes('Approve') && (
                        <Button
                          color="green"
                          icon
                          size="small"
                          onClick={() => handleBulkAction('Approve')}
                          title="Approve Selected"
                          style={{ marginRight: '5px' }}
                        >
                          <Icon name="check" style={{ color: 'white' }} />
                        </Button>
                      )}
                      {availableBulkActions.includes('Reject') && (
                        <Button
                          color="red"
                          icon
                          size="small"
                          onClick={() => handleBulkAction('Reject')}
                          title="Reject Selected"
                          style={{ marginRight: '5px' }}
                        >
                          <Icon name="remove" style={{ color: 'white' }} />
                        </Button>
                      )}
                      {availableBulkActions.includes('Resubmit') && (
                        <Button
                          color="blue"
                          icon
                          size="small"
                          onClick={() => handleBulkAction('Resubmit')}
                          title="Resubmit Selected"
                        >
                          <Icon name="refresh" style={{ color: 'white' }} />
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </Table.HeaderCell>

            </Table.Row>
          </Table.Header>

          <Table.Body>
            {finalFilteredEmployees.length > 0 ? (
              finalFilteredEmployees.map((employee, index) => {
                // Determine available actions for the employee
                const availableActions = getAvailableActionsForEmployee(employee);

                return (
                  <React.Fragment key={employee.id}>
                    <Table.Row>
                      {/* Parent Checkbox */}
                      <Table.Cell>
                        <Checkbox
                          checked={
                            selectedTimesheets[employee.id]?.size === employee.timesheet.filter(entry => {
                              const statuses = getApprovalStatusesForFilter(filter);
                              return statuses ? statuses.includes(entry.approvalStatus) : true;
                            }).length
                          }
                          indeterminate={
                            selectedTimesheets[employee.id]?.size > 0 &&
                            selectedTimesheets[employee.id]?.size < employee.timesheet.filter(entry => {
                              const statuses = getApprovalStatusesForFilter(filter);
                              return statuses ? statuses.includes(entry.approvalStatus) : true;
                            }).length
                          }
                          onChange={(e, { checked }) => handleParentCheckbox(employee.id, checked)}
                        />
                      </Table.Cell>
                      {/* Employee Details */}
                      <Table.Cell>{employee.employeeId}</Table.Cell>
                      <Table.Cell>{employee.name}</Table.Cell>
                      <Table.Cell>{employee.email}</Table.Cell>
                      <Table.Cell>{employee.role}</Table.Cell>
                      <Table.Cell>{renderWorkingStatusLabel(employee.workingStatus)}</Table.Cell>
                      {/* Allocation Metrics */}
                      <Table.Cell>{employee.allocatedHours}</Table.Cell>
                      <Table.Cell>
                        {isEditMode && activeAccordionIndex !== index ? (
                          <Popup
                            trigger={
                              <Input
                                type="number"
                                placeholder={workedHoursInput[employee.id] || workedHoursInput[employee.id] === 0 ? 'Suggested: 0' : employee.allocatedHours}
                                value={
                                  workedHoursInput[employee.id] !== undefined
                                    ? workedHoursInput[employee.id]
                                    : employee.workedHours
                                }
                                onFocus={() => {
                                  // Clear the input value when entering the input box
                                  setWorkedHoursInput((prev) => ({
                                    ...prev,
                                    [employee.id]: ''
                                  }));
                                  if (workedHoursInput[employee.id] === undefined || workedHoursInput[employee.id] === 0) {
                                    setShowSuggestion((prev) => ({
                                      ...prev,
                                      [`parent_${employee.id}`]: true,
                                    }));
                                  }
                                }}
                                onBlur={() => {
                                  setTimeout(() => {
                                    setShowSuggestion((prev) => ({
                                      ...prev,
                                      [`parent_${employee.id}`]: false,
                                    }));
                                  }, 200);
                                }}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);
                                  if (!isNaN(value) && value >= 0) {
                                    setWorkedHoursInput((prev) => ({
                                      ...prev,
                                      [employee.id]: value,
                                    }));
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Tab') {
                                    setWorkedHoursInput((prev) => ({
                                      ...prev,
                                      [employee.id]: employee.allocatedHours,
                                    }));
                                  }
                                }}
                                min="0"
                                step="1"
                                style={{ width: '100px' }}
                              />

                            }
                            open={showSuggestion[`parent_${employee.id}`] && !workedHoursInput[employee.id]}
                            content={`Suggested: ${employee.allocatedHours}`}
                            position="top center"
                            size="mini"
                            style={{ opacity: 0.9 }}
                          />
                        ) : (
                          employee.workedHours
                        )}
                      </Table.Cell>
                      <Table.Cell>{employee.workedPercentage}%</Table.Cell>
                      <Table.Cell>{employee.holidays}</Table.Cell>
                      <Table.Cell>{employee.leaves}</Table.Cell>
                      {/* Actions */}
                      <Table.Cell>
                        {availableActions.map((action) => (
                          <Button
                            key={action}
                            color={
                              action === 'Approve' ? 'green' :
                              action === 'Reject' ? 'red' :
                              action === 'Resubmit' ? 'blue' : 'grey'
                            }
                            icon
                            size="small"
                            onClick={() => handleBulkAction(action)}
                            title={action}
                            disabled={availableActions.length === 0}
                            style={{ marginRight: '5px' }}
                          >
                            {action === 'Approve' && <Icon name="check" style={{ color: 'white' }} />}
                            {action === 'Reject' && <Icon name="remove" style={{ color: 'white' }} />}
                            {action === 'Resubmit' && <Icon name="refresh" style={{ color: 'white' }} />}
                          </Button>
                        ))}
                      </Table.Cell>
                    </Table.Row>
                    {/* Timesheet Details Accordion */}
                    <Table.Row>
                      <Table.Cell colSpan="13" style={{ padding: "0" }}>
                        <Accordion fluid styled>
                          <Accordion.Title
                            active={activeAccordionIndex === index}
                            index={index}
                            onClick={() => handleAccordionClick(index, employee.id)}
                            style={{ 
                              paddingLeft: "1.5em",
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Icon name="dropdown" />
                              View Timesheet Details
                            </div>
                            {activeAccordionIndex === index && isChildEditVisible(employee) && (
                              <Button.Group>
                                <Button
                                  color={timesheetEditMode[employee.id] ? 'green' : 'blue'}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent accordion from toggling
                                    if (timesheetEditMode[employee.id]) {
                                      handleSaveTimesheetChanges(employee.id);
                                    } else {
                                      handleChildEditMode(employee.id);
                                      toast.info('Timesheet edit triggered');
                                    }
                                    setTimesheetEditMode((prev) => ({
                                      ...prev,
                                      [employee.id]: true,
                                    }));
                                  }}
                                  disabled={isSubmitting}
                                >
                                  <Icon name={timesheetEditMode[employee.id] ? 'save' : 'edit'} />
                                  {timesheetEditMode[employee.id] ? 'Save Timesheet' : 'Edit Timesheet'}
                                </Button>
                                {timesheetEditMode[employee.id] && (
                                  <>
                                    <Button
                                      color="blue"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent accordion from toggling
                                        handleResetChildEdit(employee.id);
                                      }}
                                      disabled={isSubmitting}
                                    >
                                      <Icon name="undo" />
                                      Reset
                                    </Button>
                                    <Button
                                      color="grey"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent accordion from toggling
                                        handleDiscardChildEdit(employee.id);
                                      }}
                                      disabled={isSubmitting}
                                    >
                                      <Icon name="cancel" />
                                      Discard
                                    </Button>
                                  </>
                                )}
                              </Button.Group>
                            )}
                          </Accordion.Title>
                          <Accordion.Content active={activeAccordionIndex === index}>
                            <Table celled size="small" compact>
                              <Table.Header>
                                <Table.Row>
                                  {/* Child Checkbox Header */}
                                  <Table.HeaderCell style={{ width: '40px' }} />
                                  <Table.HeaderCell
                                    style={{ width: '100px' }}
                                    sorted={
                                      sortConfig[employee.id]?.column === 'allocationId'
                                        ? sortConfig[employee.id].direction
                                        : null
                                    }
                                    onClick={() => handleSort(employee.id, 'allocationId')}
                                  >
                                    Allocation ID
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    sorted={
                                      sortConfig[employee.id]?.column === 'date'
                                        ? sortConfig[employee.id].direction
                                        : null
                                    }
                                    onClick={() => handleSort(employee.id, 'date')}
                                  >
                                    Date
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    sorted={
                                      sortConfig[employee.id]?.column === 'allocatedHours'
                                        ? sortConfig[employee.id].direction
                                        : null
                                    }
                                    onClick={() => handleSort(employee.id, 'allocatedHours')}
                                  >
                                    Allocated Hours
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    sorted={
                                      sortConfig[employee.id]?.column === 'workedHours'
                                        ? sortConfig[employee.id].direction
                                        : null
                                    }
                                    onClick={() => handleSort(employee.id, 'workedHours')}
                                  >
                                    Worked Hours
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    sorted={
                                      sortConfig[employee.id]?.column === 'approvalStatus'
                                        ? sortConfig[employee.id].direction
                                        : null
                                    }
                                    onClick={() => handleSort(employee.id, 'approvalStatus')}
                                  >
                                    Timesheet Status
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    sorted={
                                      sortConfig[employee.id]?.column === 'holiday'
                                        ? sortConfig[employee.id].direction
                                        : null
                                    }
                                    onClick={() => handleSort(employee.id, 'holiday')}
                                  >
                                    Holiday
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    sorted={
                                      sortConfig[employee.id]?.column === 'leave'
                                        ? sortConfig[employee.id].direction
                                        : null
                                    }
                                    onClick={() => handleSort(employee.id, 'leave')}
                                  >
                                    Leave
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>Allocation %</Table.HeaderCell> {/* New Column */}
                                  <Table.HeaderCell>Allocation Status</Table.HeaderCell> {/* New Column */}
                                  <Table.HeaderCell style={{ width: '270px' }}>Comment</Table.HeaderCell>
                                  <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {getSortedTimesheet(employee.id, 
                                  filter === 'all' ? employee.timesheet :
                                  employee.timesheet.filter(entry => {
                                    const statuses = getApprovalStatusesForFilter(filter);
                                    return statuses ? statuses.includes(entry.approvalStatus) : true;
                                  })
                                ).map((entry) => {
                                  // Determine available actions for the timesheet entry
                                  let entryActions = [];
                                  switch (entry.approvalStatus) {
                                    case 0: // Not Submitted
                                      entryActions = [];
                                      break;
                                    case 1: // Submitted
                                      entryActions = ['Approve', 'Reject'];
                                      break;
                                    case 2: // Approved
                                      entryActions = ['Resubmit'];
                                      break;
                                    case 3: // Rejected
                                      entryActions = ['Resubmit'];
                                      break;
                                    default:
                                      entryActions = [];
                                  }

                                  return (
                                    <Table.Row key={entry.guidId}> {/* Use guidId as key */}
                                      {/* Child Checkbox */}
                                      <Table.Cell>
                                        <Checkbox
                                          checked={selectedTimesheets[employee.id]?.has(entry.guidId)}
                                          onChange={(e, { checked }) =>
                                            handleChildCheckbox(employee.id, entry.guidId, checked)
                                          }
                                        />
                                      </Table.Cell>
                                      {/* Timesheet Details */}
                                      <Table.Cell>{employee.allocationId}</Table.Cell> {/* Updated to use allocationId */}
                                      <Table.Cell>{formatDate(new Date(entry.date))}</Table.Cell>
                                      <Table.Cell>{entry.allocatedHours}</Table.Cell>
                                      <Table.Cell>
                                        {timesheetEditMode[employee.id] ? (
                                          <Popup
                                            trigger={
                                              <Input
                                                type="number"
                                                placeholder={
                                                  holidayStatus[employee.id]?.[entry.guidId]
                                                    ? 'Suggested: 0'
                                                    : timesheetWorkedHours[employee.id]?.[entry.guidId] === undefined ||
                                                      timesheetWorkedHours[employee.id]?.[entry.guidId] === 0
                                                    ? `Suggested: ${entry.allocatedHours}`
                                                    : entry.workedHours
                                                }
                                                value={
                                                  holidayStatus[employee.id]?.[entry.guidId]
                                                    ? 0
                                                    : timesheetWorkedHours[employee.id]?.[entry.guidId] !== undefined
                                                    ? timesheetWorkedHours[employee.id][entry.guidId]
                                                    : entry.workedHours
                                                }
                                                onFocus={() => {
                                                  // Clear the input value when entering the input box
                                                  handleTimesheetWorkedHoursChange(employee.id, entry.guidId, '');
                                                  if (
                                                    timesheetWorkedHours[employee.id]?.[entry.guidId] === undefined ||
                                                    timesheetWorkedHours[employee.id]?.[entry.guidId] === 0
                                                  ) {
                                                    handleTimesheetWorkedHoursChange(employee.id, entry.guidId, '');
                                                    setShowSuggestion((prev) => ({
                                                      ...prev,
                                                      [`timesheet_${employee.id}_${entry.guidId}`]: true,
                                                    }));
                                                  }
                                                }}
                                                onBlur={() => {
                                                  setTimeout(() => {
                                                    setShowSuggestion((prev) => ({
                                                      ...prev,
                                                      [`timesheet_${employee.id}_${entry.guidId}`]: false,
                                                    }));
                                                  }, 200);
                                                }}
                                                onChange={(e) => {
                                                  const value = parseFloat(e.target.value);
                                                  if (!isNaN(value) && value >= 0) {
                                                    handleTimesheetWorkedHoursChange(employee.id, entry.guidId, value);
                                                  }
                                                }}
                                                onKeyDown={(e) => {
                                                  if (e.key === 'Tab') {
                                                    handleTimesheetWorkedHoursChange(employee.id, entry.guidId, entry.allocatedHours);
                                                  }
                                                }}
                                                min="0"
                                                step="1"
                                                style={{
                                                  width: '80px',
                                                  backgroundColor: !timesheetWorkedHours[employee.id]?.[entry.guidId] ? '#f9f9f9' : 'white',
                                                }}
                                                disabled={holidayStatus[employee.id]?.[entry.guidId]}
                                              />
                                            }
                                            open={showSuggestion[`timesheet_${employee.id}_${entry.guidId}`] && 
                                                  !timesheetWorkedHours[employee.id]?.[entry.guidId]}
                                            content={`Suggested: ${holidayStatus[employee.id]?.[entry.guidId] ? 0 : entry.allocatedHours}`}
                                            position="top center"
                                            size="mini"
                                            style={{ opacity: 0.9 }}
                                          />
                                        ) : (
                                          entry.workedHours
                                        )}
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Label color={APPROVAL_STATUS_COLOR[entry.approvalStatus]}>
                                          {APPROVAL_STATUS[entry.approvalStatus]}
                                        </Label>
                                      </Table.Cell>
                                      <Table.Cell>
                                        {timesheetEditMode[employee.id] ? (
                                          <Checkbox
                                            checked={
                                              holidayStatus[employee.id]?.[entry.guidId] !== undefined
                                                ? holidayStatus[employee.id][entry.guidId]
                                                : entry.holiday
                                            }
                                            onChange={(e, { checked }) => handleHolidayChange(employee.id, entry.guidId, checked)}
                                            disabled={leaveStatus[employee.id]?.[entry.guidId]}
                                          />
                                        ) : (
                                          entry.holiday
                                        )}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {timesheetEditMode[employee.id] ? (
                                          <Checkbox
                                            checked={
                                              leaveStatus[employee.id]?.[entry.guidId] !== undefined
                                                ? leaveStatus[employee.id][entry.guidId]
                                                : entry.leave
                                            }
                                            onChange={(e, { checked }) => handleLeaveChange(employee.id, entry.guidId, checked)}
                                            disabled={holidayStatus[employee.id]?.[entry.guidId]}
                                          />
                                        ) : (
                                          entry.leave
                                        )}
                                      </Table.Cell>
                                      <Table.Cell>{entry.allocationPercentage}%</Table.Cell> {/* Allocation % */}
                                      <Table.Cell>{entry.allocationStatus}</Table.Cell> {/* Allocation Status */}
                                      <Table.Cell>
                                        {!timesheetEditMode[employee.id] && (
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Input
                                              placeholder="Comment"
                                              value={(timesheetComments[employee.id] && timesheetComments[employee.id][entry.guidId]) || entry.comment || ''}
                                              readOnly
                                              style={{
                                                width: '200px',
                                                marginRight: '10px',
                                              }}
                                            />

                                            {entry.approvalStatus === 1 && (
                                              <Popup
                                                trigger={<Button icon="edit" size="small" />}
                                                on="click"
                                                position="right center"
                                                flowing
                                                hoverable
                                                style={{ zIndex: 2000 }}
                                              >
                                                <Dropdown
                                                  placeholder="Select a reason..."
                                                  selection
                                                  options={[
                                                    { key: 'placeholder', value: null, text: 'Select a reason...', disabled: true },
                                                    ...rejectionReasons
                                                  ]}
                                                  value={selectedRejectionReasons[employee.id]?.[entry.guidId] || null}
                                                  onChange={(e, { value }) => {
                                                    handleCommentChange(employee.id, entry.guidId, value);
                                                    setSelectedRejectionReasons((prev) => ({
                                                      ...prev,
                                                      [employee.id]: { ...prev[employee.id], [entry.guidId]: value }
                                                    }));
                                                  }}
                                                  clearable
                                                  openOnFocus
                                                  direction="down"
                                                  pointing="below"
                                                  style={{
                                                    minWidth: '200px',
                                                    zIndex: 1000,
                                                  }}
                                                  fluid
                                                  selectOnBlur={false}
                                                />
                                              </Popup>
                                            )}
                                          </div>
                                        )}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {!timesheetEditMode[employee.id] && (
                                          entryActions.map((action) => (
                                            <Button
                                              key={action}
                                              color={
                                                action === 'Approve' ? 'green' :
                                                action === 'Reject' ? 'red' :
                                                action === 'Resubmit' ? 'blue' : 'grey'
                                              }
                                              icon
                                              size="small"
                                              onClick={() => handleTimesheetAction(action, employee.id, entry.guidId)}
                                              title={action}
                                              disabled={
                                                (action === 'Reject' && !(selectedRejectionReasons[employee.id] && selectedRejectionReasons[employee.id][entry.guidId])) ||
                                                (action === 'Approve' && selectedRejectionReasons[employee.id] && selectedRejectionReasons[employee.id][entry.guidId])
                                              }
                                              style={{ marginRight: '5px' }}
                                            >
                                              {action === 'Approve' && <Icon name="check" style={{ color: 'white' }} />}
                                              {action === 'Reject' && <Icon name="remove" style={{ color: 'white' }} />}
                                              {action === 'Resubmit' && <Icon name="refresh" style={{ color: 'white' }} />}
                                            </Button>
                                          ))
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  );
                                })}
                              </Table.Body>
                            </Table>
                          </Accordion.Content>
                        </Accordion>
                      </Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="13" textAlign="center">
                  No employees found with the selected filter and search criteria.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>

      {/* Confirmation Modal */}
      <Modal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        size="small"
      >
        <Modal.Header>Unsaved Changes</Modal.Header>
        <Modal.Content>
          <p>Do you want to close? Unsaved changes will be discarded.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleConfirmationAction('discard')}>Continue without Saving</Button>
          <Button color="green" onClick={() => handleConfirmationAction('save')}>Save Changes</Button>
        </Modal.Actions>
      </Modal>
    </>

  );

  // Helper function to render working status label with colors
  function renderWorkingStatusLabel(status) {
    switch (status.toLowerCase()) {
      case "active":
        return <Label color="green" style={{ borderRadius: "12px" }}>Active</Label>;
      case "in-active":
        return <Label color="orange" style={{ borderRadius: "12px" }}>In-Active</Label>;
      case "closed":
        return <Label color="grey" style={{ borderRadius: "12px" }}>Closed</Label>;
      default:
        return <Label color="grey" style={{ borderRadius: "12px" }}>{status}</Label>;
    }
  }
};

export default EmployeeAllocationsManualUpload;
