// src/config/config.js
export const CONFIG = {
  batchSize: 1000, // Move batch size here
  APPROVAL_STATUS_NOT_SUBMITTED: "Not Submitted", // Moved from MyTimesheets.js
  APPROVAL_STATUS_REJECTED: "Rejected", // Moved from MyTimesheets.js
  APPROVAL_STATUS_SUBMITTED: "Submitted", // Moved from MyTimesheets.js
  MAX_HOURS_PER_DAY: 24,
  MIN_HOURS: 0,
  DEFAULT_ALLOCATION_HOURS: "8",
  DEFAULT_DAY_NAME: "Unknown Day",
  TIMESHEET_UNLOCK: 0,
  APPROVAL_STATUS_MAP: {
    0: "Not Submitted",
    1: "Submitted",
    2: "Approved",
    3: "Rejected",
  },
  VIEW_MODES: {
    WEEK: "week",
    DAY: "day",
  },
  STATUS_OPTIONS: [
    { key: "normal", text: "Normal", value: "normal" },
    { key: "holiday", text: "Holiday", value: "holiday" },
    { key: "leave", text: "On Leave", value: "leave" },
  ],
};