// src/components/AllocationModal/AllocationModal.jsx

import React, { useState, useEffect, useContext } from 'react';
import { 
  Modal, 
  Button, 
  Form, 
  Dropdown, 
  Loader, 
  Message, 
  Input, 
  Grid, 
  Segment, 
  Header 
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ProfileContext } from '../../context/ProfileContext';
import { UserContext } from '../../context/UserContext';
import useAxios from '../../axiosSetup';
import { Messages } from '../../config/messages';

const AllocationModal = ({
  open,
  onClose,
  onSave,
  employeeData,          
  clientProjectData,     
  allocationData,        
  userRole,
}) => {
  const { displayName } = useContext(ProfileContext);

  // Initialize state with either existing allocation data or default values
  const [formData, setFormData] = useState({
    employeeName: '',
    employeeId: '',
    clientId: '',
    projectId: '',
    status: '',
    allocationPercent: '',
    allocationPercentDropdown: '',
    billingType: '',
    billedCheck: '',
    timeSheetApprover: '',
    timeSheetApproverId: '',
    startDate: '',
    endDate: '',
  });

  const { jwtToken } = useContext(UserContext);
  const axiosInstance = useAxios();
  const [error, setError] = useState(null);
  const [allocationTotalWarning, setAllocationTotalWarning] = useState(false); // New state for Total allocation warning
  const [allocationWarning, setAllocationWarning] = useState(false); // New state for Allocation Warning
  const [allocationDropdownClicked, setAllocationDropdownClicked] = useState(false); // New Warning State to track dropdown click
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [timeSheetApprovers, setTimeSheetApprovers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [fetchedRemainingAllocation, setFetchedRemainingAllocation] = useState(100);
  const [originalAllocationPercent, setOriginalAllocationPercent] = useState(0);
  const [billingEnabled, setBillingEnabled] = useState('no');
  const [allocation, setAllocation] = useState(0); // For circular progress

  // New States for Date Constraints
  const [isEndDateDisabled, setIsEndDateDisabled] = useState(true);
  const [minEndDate, setMinEndDate] = useState('');

  const [totalAllocation, setTotalAllocation] = useState(0);
  const [remainingAllocation, setRemainingAllocation] = useState(100);
  const [currentAllocation, setCurrentAllocation] = useState(0);

  const [billingOptions, setBillingOptions] = useState({
    billedCheck: [],
    billingType: [],
  });
  
  const [billingTypeOptions, setBillingTypeOptions] = useState([]);
  
  // Effect to update billingTypeOptions dynamically when billedCheck changes
  useEffect(() => {
    const fetchBillingOptions = async () => {
      setLoading(true);
      setFetchError(null);
  
      try {
        const response = await axiosInstance.get(`/api/modal/data`);
        const { billingOptions } = response.data;
        setBillingOptions({
          billedCheck: billingOptions.BilledCheck || [],
          billingType: billingOptions.BillingType || [],
        });
      } catch (err) {
        console.error('Error fetching billing options:', err);
        setFetchError('Failed to load billing options.');
      } finally {
        setLoading(false);
      }
    };
  
    if (open) {
      fetchBillingOptions();
    }
  }, [open]);
  

  useEffect(() => {
    if (allocationData) {
      // Do not override timeSheetApprover when editing
      return;
    }
    if (formData.clientId) {
      const associatedProjects = projects.filter(
        project => project.ClientID === formData.clientId
      );

      if (associatedProjects.length > 0) {
        const defaultApprover = associatedProjects[0].ProjectManager;
        setFormData(prev => ({
          ...prev,
          timeSheetApprover: defaultApprover || '', // Set to ProjectManager or empty string if undefined
        }));
      } else {
        // If no projects are associated, clear the Time Sheet Approver
        setFormData(prev => ({
          ...prev,
          timeSheetApprover: '',
        }));
      }
    } else {
      // If no client is selected, clear the Time Sheet Approver
      setFormData(prev => ({
        ...prev,
        timeSheetApprover: '',
      }));
    }
  }, [formData.clientId, projects, allocationData]);

  useEffect(() => {
    const fetchTotalAllocation = async () => {
      if (!formData.employeeId || !formData.startDate || !formData.endDate) return;

      try {
        const response = await axiosInstance.get(`api/employeeAllocations/${formData.employeeId}`, {
          params: {
            startDate: formData.startDate,
            endDate: formData.endDate
          }
        });
        const newTotalAllocation = 100 - response.data.remainingAllocation;
        setTotalAllocation(newTotalAllocation);
        setRemainingAllocation(response.data.remainingAllocation);

        // If editing an existing allocation, set the current allocation
        if (allocationData) {
          const existingAllocation = allocationData.AllocationPercent || 0;
          setCurrentAllocation(existingAllocation);
          setFormData(prev => ({
            ...prev,
            allocationPercent: existingAllocation.toString(),
          }));
        }

        // Set warning if total allocation is 50% or below
        setAllocationTotalWarning(newTotalAllocation < 50 && newTotalAllocation > 0);
      } catch (err) {
        console.error('Error fetching total allocation:', err);
        setError('Failed to compute total allocation.');
      }
    };

    if (open) {
      fetchTotalAllocation();
    }
  }, [open, formData.employeeId, formData.startDate, formData.endDate, allocationData]);

// Fetch clients when the modal opens
useEffect(() => {
  const fetchClients = async () => {
    setLoading(true);
    setFetchError(null);
    try {
      const response = await axiosInstance.get(`/api/modal/clients`);
      const { clients } = response.data;
      setClients(clients);
      console.log("clients:", clients)
    } catch (err) {
      setFetchError('Failed to load client data.');
    } finally {
      setLoading(false);
    }
  };

  if (open) {
    fetchClients();
  }
}, [open]);

// Fetch projects associated with respective client
useEffect(() => {
  const fetchProjects = async () => {
    setLoading(true);
    setFetchError(null);
    try {
      const response = await axiosInstance.get(`/api/modal/projects`, {
        params: { clientId: formData.clientId },
      });
      const { projects } = response.data;
      setProjects(projects);
      console.log("projects", projects);
    } catch (err) {
      setFetchError('Failed to load project data.');
    } finally {
      setLoading(false);
    }
  };

  if (formData.clientId) {
    fetchProjects();
  }
}, [formData.clientId]);

// Fetch approver details when a project is selected
useEffect(() => {
  const fetchApproverData = async () => {
    setLoading(true);
    setFetchError(null);
    try {
      const response = await axiosInstance.get(`/api/modal/approver`, {
        params: { projectId: formData.projectId },
      });
      const { approvers } = response.data;
  
      // Update approver options with correct roles
      setTimeSheetApprovers(
        approvers.map((approver) => ({
          approverId: approver.approverId,
          approverName: approver.approverName,
          role: approver.role, // Use the role from the backend
        }))
      );
    } catch (err) {
      console.error('Error fetching approver data:', err);
      setFetchError('Failed to load approver data.');
    } finally {
      setLoading(false);
    }
  };
    

  if (formData.projectId) {
    fetchApproverData();
  }
}, [formData.projectId]);



  // Fetch remaining allocation when employeeData or allocationData changes
  useEffect(() => {
    const fetchRemainingAllocation = async () => {
      if (!formData.employeeId || !formData.startDate || !formData.endDate) return;

      try {
        const response = await axiosInstance.get(`/api/employeeAllocations/${formData.employeeId}`, {
          params: {
            startDate: formData.startDate,
            endDate: formData.endDate
          }
        });
        setFetchedRemainingAllocation(response.data.remainingAllocation);
        setAllocation(100 - response.data.remainingAllocation); // Update allocation for the donut chart
      } catch (err) {
        console.error('Error fetching remaining allocation:', err);
        setError('Failed to compute remaining allocation.');
      }
    };

    if (open && formData.employeeId && formData.startDate && formData.endDate) {
      fetchRemainingAllocation();
    }
  }, [open, formData.employeeId, formData.startDate, formData.endDate]);

  // Update formData when allocationData, employeeData, or clientProjectData changes
  useEffect(() => {
    if (allocationData) {
      setFormData({
        // Use employeeData for Employee Name and ID
        employeeName: employeeData ? employeeData.EmployeeName : '',
        employeeId: employeeData ? employeeData.EmployeeId : '',
        clientId: allocationData.ClientID || '',
        projectId: allocationData.ProjectID || '',
        status: allocationData.AllocationStatus || '',
        allocationPercentDropdown: allocationData.allocationPercentDropdown ? allocationData.allocationPercentDropdown.toString() : '',
        allocationPercent: allocationData.AllocationPercent ? allocationData.AllocationPercent.toString() : '',
        billingType: allocationData.AllocationBillingType || '',
        billedCheck: allocationData.AllocationBilledCheck || '',
        timeSheetApprover: allocationData.AllocationTimeSheetApprover || '',
        timeSheetApproverId: allocationData.AllocationTimeSheetApproverID || '',
        startDate: allocationData.AllocationStartDate ? allocationData.AllocationStartDate.substring(0, 10) : '',
        endDate: allocationData.AllocationEndDate ? allocationData.AllocationEndDate.substring(0, 10) : '',
      });
      setOriginalAllocationPercent(allocationData.AllocationPercent || 0);
      setAllocation(allocationData.AllocationPercent || 0);

      // Enable End Date field if Start Date is present
      if (allocationData.AllocationStartDate) {
        setIsEndDateDisabled(false);
        setMinEndDate(allocationData.AllocationStartDate.substring(0, 10));
      }
    } else {
      // Reset form for adding new allocation
      setFormData({
        employeeName: employeeData ? employeeData.EmployeeName : '',
        employeeId: employeeData ? employeeData.EmployeeId : '',
        clientId: clientProjectData ? clientProjectData.clientId : '',
        projectId: clientProjectData ? clientProjectData.projectId : '',
        status: '',
        allocationPercentDropdown: '',
        allocationPercent: '',
        billingType: '',
        billedCheck: '',
        timeSheetApprover: '',
        timeSheetApproverId: '',
        startDate: '',
        endDate: '',
      });
      setOriginalAllocationPercent(0);
      setAllocation(0);
      setIsEndDateDisabled(true);
      setMinEndDate('');
    }

    // Reset error when allocationData changes
    setError(null);
  }, [allocationData, employeeData, clientProjectData]);

  // Reset form when modal is closed
  useEffect(() => {
    if (!open) {
      // Reset form when modal is closed
      setFormData({
        employeeName: employeeData ? employeeData.EmployeeName : '',
        employeeId: employeeData ? employeeData.EmployeeId : '',
        clientId: clientProjectData ? clientProjectData.clientId : '',
        projectId: clientProjectData ? clientProjectData.projectId : '',
        status: '',
        allocationPercent: '',
        billingType: '',
        billedCheck: '',
        timeSheetApprover: '',
        timeSheetApproverId: allocationData ? allocationData.AllocationTimeSheetApproverID || '' : '',
        startDate: '',
        endDate: '',
      });
      setError(null);
      setFetchError(null);
      setFetchedRemainingAllocation(100);
      setOriginalAllocationPercent(0);
      setRemainingAllocation(100);
      setBillingEnabled('no');
      setAllocation(0);
      setIsEndDateDisabled(true);
      setMinEndDate('');
      setAllocationTotalWarning(false); // Reset Total allocation warning when modal closes
      setAllocationWarning(false); // Reset Test
      setAllocationDropdownClicked(false); // Reset Test
    }
  }, [open, employeeData, clientProjectData, allocationData]);

  // Compute Remaining Allocation Dynamically
  useEffect(() => {
    let newRemaining = 0;
    const currentAllocationPercent = parseInt(formData.allocationPercent, 10) || 0;

    if (allocationData) {
      // If editing, subtract the current allocation percent from total allocation
      newRemaining = fetchedRemainingAllocation + (originalAllocationPercent || 0) - currentAllocationPercent;
    } else {
      // Adding a new allocation
      newRemaining = fetchedRemainingAllocation - currentAllocationPercent;
    }

    setRemainingAllocation(newRemaining >= 0 ? newRemaining : 0);
  }, [fetchedRemainingAllocation, originalAllocationPercent, formData.allocationPercent, allocationData]);

  // Helper function to check date overlap
  const isOverlapping = (newStart, newEnd, existingStart, existingEnd) => {
    const startA = new Date(newStart);
    const endA = newEnd ? new Date(newEnd) : new Date('9999-12-31');
    const startB = new Date(existingStart);
    const endB = existingEnd ? new Date(existingEnd) : new Date('9999-12-31');

    return startA <= endB && startB <= endA;
  };

  // Auto-populate Employee Name when Employee ID is entered and vice versa
  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      if (formData.employeeId && !employeeData) {
        try {
          const response = await axiosInstance.get(`/api/employees/${formData.employeeId}`);
          if (response.data) {
            setFormData(prev => ({
              ...prev,
              employeeName: response.data.EmployeeName || '',
            }));
          }
        } catch (err) {
          console.error('Error fetching employee details:', err);
          setError('Invalid Employee ID.');
        }
      }
    };

    const fetchEmployeeId = async () => {
      if (formData.employeeName && !employeeData) {
        try {
          const response = await axiosInstance.get(`/api/employees?name=${formData.employeeName}`);
          if (response.data && response.data.EmployeeId) {
            setFormData(prev => ({
              ...prev,
              employeeId: response.data.EmployeeId || '',
            }));
          }
        } catch (err) {
          console.error('Error fetching employee ID:', err);
          setError('Invalid Employee Name.');
        }
      }
    };

    if (formData.employeeId && !formData.employeeName) {
      fetchEmployeeDetails();
    }

    if (formData.employeeName && !formData.employeeId) {
      fetchEmployeeId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.employeeId, formData.employeeName]);

// Handle form field changes
const handleChange = (e, { name, value }) => {
  setFormData((prev) => ({
    
    ...prev,
    [name]: value,
  }));

    if (name === 'allocationPercent') {
      const newAllocationPercent = parseInt(value, 10) || 0;
      setCurrentAllocation(newAllocationPercent);
      if (newAllocationPercent < 50 && totalAllocation < 50) {
        toast.warn(Messages.allocationBelow50Warning);
      }
    }
    if (name === 'allocationPercentDropdown') {
      setFormData((prev) => ({
        ...prev,
        allocationPercent: value,
      }));
      const dropdownValue = parseInt(value, 10) || 0;
      setAllocation(dropdownValue);
      if (dropdownValue < 50 && totalAllocation < 50 && (dropdownValue + totalAllocation) < 50) {
        toast.warn(Messages.allocationBelow50Warning);
      }
    }

    if (name === 'billedCheck') {
      const isBilledNo = value === 'No';
    
      setFormData((prev) => ({
        ...prev,
        billedCheck: value,
        billingType: isBilledNo ? 'Internal' : '', // Default to 'Internal' if 'No', reset otherwise
      }));
    }
    

  if (name === 'timeSheetApprover') {
    // The value is the EmployeeID
    const approver = getTimeSheetApproverOptions().find(option => option.value === value);
  
    if (approver) {
      const nameWithRoles = approver.text;
      const name = nameWithRoles.split('(')[0].trim(); // Extract the name before any roles
      setFormData(prev => ({
        ...prev,
        timeSheetApproverId: value,
        timeSheetApprover: name || '',
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        timeSheetApproverId: '',
        timeSheetApprover: '',
      }));
    }
  } else if (name === 'projectId') {
    const selectedProject = projects.find(project => project.ProjectID === value);
    if (selectedProject) {
      // Set Client ID automatically based on selected Project
      setFormData((prev) => ({
        ...prev,
        projectId: value,
        clientId: prev.clientId || selectedProject.ClientID,
      }));

      // Fetch and set the Project Manager as default approver
      const fetchProjectManager = async () => {
        try {
          const response = await axiosInstance.get(`/api/modal/approver`, {
            params: { projectId: value },
          });
          const { approvers } = response.data;
          
          // Find the Project Manager from approvers
          const projectManager = approvers.find(approver => approver.role === 'Project Manager');
          
          if (projectManager) {
            setFormData(prev => ({
              ...prev,
              timeSheetApproverId: projectManager.approverId,
              timeSheetApprover: projectManager.approverName,
            }));
          }
        } catch (err) {
          console.error('Error fetching project manager:', err);
        }
      };

      fetchProjectManager();
    } else {
      setFormData((prev) => ({
        ...prev,
        timeSheetApproverId: '',
        timeSheetApprover: '',
      }));
    }
  }

  // Dynamically update status based on allocation percentage and selected fields
  if (name === 'allocationPercent') {
    const allocationValue = parseInt(value, 10);
    if (
      formData.clientId &&
      formData.projectId &&
      (allocationValue === 0)
    ) {
      setFormData((prev) => ({ ...prev, status: 'Project Unallocated' }));
    } else if (
      formData.clientId &&
      formData.projectId &&
      allocationValue > 0
    ) {
      setFormData((prev) => ({ ...prev, status: 'Allocated' }));
    } else if (formData.clientId && !formData.projectId) {
      setFormData((prev) => ({ ...prev, status: 'Client Unallocated' }));
    } else {
      setFormData((prev) => ({ ...prev, status: '' }));
    }
  }

  // Reset error when user modifies any field
  setError(null);
};

  // Validate form fields (pure function without side effects)
  const isFormValid = () => {
    const {
      clientId,
      projectId,
      status,
      allocationPercent,
      billingType,
      billedCheck,
      timeSheetApprover,
      timeSheetApproverId,
      startDate,
      endDate,
    } = formData;

    // Basic required fields validation
    if (
      (!clientProjectData && (!clientId || !projectId)) || // If not prefilled, client and project are required
      !status ||
      allocationPercent === '' ||
      !billingType ||
      !billedCheck ||
      !timeSheetApprover ||
      !timeSheetApproverId ||
      !startDate ||
      !endDate // Ensure endDate is always required
    ) {
      return false;
    }

    // Check if start date is before 2020
    const start = new Date(startDate);
    const minStartDate = new Date('2020-01-01');
    if (start < minStartDate) {
      return false;
    }

    // Additional validation for AllocationEndDate
    if (endDate && startDate && new Date(endDate) < new Date(startDate)) {
      return false;
    }

    return true;
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Basic validation
    if (!isFormValid()) {
      // Determine the specific error message
      const {
        clientId,
        projectId,
        status,
        allocationPercent,
        billingType,
        billedCheck,
        timeSheetApprover,
        timeSheetApproverId,
        startDate,
        endDate,
      } = formData;

      if (
        (!clientProjectData && (!clientId || !projectId)) ||
        !status ||
        allocationPercent === '' ||
        !billingType ||
        !billedCheck ||
        !timeSheetApprover ||
        !timeSheetApproverId ||
        !startDate ||
        !endDate
      ) {
        setError('Please fill in all required fields correctly.');
        return;
      }

      if (billedCheck === 'Yes') {
        // Removed Billing Rate validation
      }

      const start = new Date(startDate);
      const minStartDate = new Date('2020-01-01');
      if (start < minStartDate) {
        setError('Start Date cannot be before January 1, 2020.');
        return;
      }

      if (endDate && startDate && new Date(endDate) < new Date(startDate)) {
        setError('End Date cannot be before Start Date.');
        return;
      }

      // If none of the above, set a generic error
      setError('Please fill in all required fields correctly.');
      return;
    }

    // Validate Billing Type only if Billed? is Yes
    if (formData.billedCheck === 'Yes') {
      // No Billing Rate validation needed
    }

    try {
      // Check for overlapping allocations
      const overlapResponse = await axiosInstance.get(`/api/employeeAllocationDetails/${formData.employeeId}/allocations`);
      const allocationsList = overlapResponse.data.allocations;

      const hasOverlap = allocationsList.some(alloc => {
        // If editing, exclude the current allocation
        if (allocationData && alloc.AllocationID === allocationData.AllocationID) {
          return false;
        }

        // Check if the project is the same
        if (alloc.ProjectID !== formData.projectId) {
          return false;
        }

        // Check for date overlap
        return isOverlapping(
          formData.startDate,
          formData.endDate,
          alloc.AllocationStartDate,
          alloc.AllocationEndDate
        );
      });

      if (hasOverlap) {
        setError('Allocation overlaps with an existing allocation for the same project.');
        return;
      }

      // Check total allocation does not exceed 100%
      if (allocationData) {
        // If editing, subtract the current allocation percent from total allocation
        const totalAllocationResponse = await axiosInstance.get(`/api/employeeAllocations/${formData.employeeId}`, {
          params: {
            startDate: formData.startDate,
            endDate: formData.endDate
          }
        });
        const totalAllocation = 100 - totalAllocationResponse.data.remainingAllocation;
        const adjustedTotal = totalAllocation - originalAllocationPercent + parseInt(formData.allocationPercent, 10);

        if (adjustedTotal > 100) {
          setError('Total allocation percentage cannot exceed 100%.');
          return;
        }
      } else {
        // If adding new allocation
        const totalAllocationResponse = await axiosInstance.get(`/api/employeeAllocations/${formData.employeeId}`, {
          params: {
            startDate: formData.startDate,
            endDate: formData.endDate
          }
        });
        const totalAllocation = 100 - totalAllocationResponse.data.remainingAllocation;

        if (totalAllocation + parseInt(formData.allocationPercent, 10) > 100) {
          setError('Total allocation percentage cannot exceed 100%.');
          return;
        }
      }

      // Prepare the payload
      const payload = {
        EmployeeID: formData.employeeId,
        ClientID: formData.clientId,
        ProjectID: formData.projectId,
        AllocationStatus: formData.status,
        AllocationPercentDropdown: parseInt(formData.allocationPercentDropdown, 10),
        AllocationPercent: parseInt(formData.allocationPercent, 10),
        AllocationStartDate: formData.startDate,
        AllocationEndDate: formData.endDate || null,
        AllocationTimeSheetApproverID: formData.timeSheetApproverId,
        AllocationTimeSheetApprover: formData.timeSheetApprover,
        AllocationBillingType: formData.billingType,
        AllocationBilledCheck: formData.billedCheck,
        // Removed AllocationBillingRate from payload
        ModifiedBy: displayName,
      };
      
      if (allocationData && allocationData.AllocationID) {
        // Editing existing allocation
        await axiosInstance.put(`/api/allocations/${allocationData.AllocationID}`, payload);
        toast.success('Allocation updated successfully!');
      } else {
        // Adding new allocation
        await axiosInstance.post(`api/api/allocate`, payload); // Corrected API endpoint
        toast.success('Allocation added successfully!');
      }

      onSave(); // Refresh allocations in parent component
      onClose(); // Close the modal
    } catch (err) {
      console.error('Error adding/updating allocation:', err);
      setError(err.response?.data?.message || 'Failed to add/update allocation.');
      setFormData(prev => ({
        ...prev,
        allocationPercentDropdown: '', // Reset allocationPercentDropdown to empty
      }));
    }
  };

  // Helper function to generate project options based on selected client
  const getFilteredProjectOptions = () => {
    if (formData.clientId === '') {
      return [];
    }
    return projects.map((project) => ({
      key: project.ProjectID,
      text: project.ProjectName,
      value: project.ProjectID,
    }));
  };
  

  const AllocationPercentOptions = [
    { key: 0, text: '0%', value: '0' },
    { key: 25, text: '25%', value: '25' },
    { key: 50, text: '50%', value: '50' },
    { key: 75, text: '75%', value: '75' },
    { key: 100, text: '100%', value: '100' },
  ];
  
  const selectedProject = projects.find(
    (project) => project.ProjectID === formData.projectId
  );
  
  // Updated Helper function to get Time Sheet Approver options with multiple role tags
  const getTimeSheetApproverOptions = () => {
    return timeSheetApprovers.map((approver) => ({
      key: approver.approverId,
      text: `${approver.approverName} (${approver.role})`, // Append role to name
      value: approver.approverId,
      content: (
        <div>
          {approver.approverName}
          <span style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'Black', marginLeft: '5px' }}>
            ({approver.role})
          </span>
        </div>
      ),
    }));
  };
  

  // Handle Billing Radio Change
  const handleBillingChange = (e, { value }) => {
    setFormData((prev) => ({
      ...prev,
      billedCheck: value,
      // Removed billingRate logic
    }));
  };

  // Custom styles for Allocation Percent Dropdown when warning is active
  const allocationDropdownStyle = allocationTotalWarning ? { borderColor: '#fbbd08 !important', backgroundColor: '#fff8e1' } : {};

  const handleDropdownClick = () => {
    setAllocationDropdownClicked(true);
  };

  return (
    <Modal open={open} onClose={onClose} size="large" closeIcon>
      <Modal.Header>
        {allocationData ? 'Edit Allocation' : 'Add New Allocation'}
      </Modal.Header>
      <Modal.Content scrolling>
        {loading ? (
          <Loader active inline="centered" />
        ) : fetchError ? (
          <Message negative>
            <Message.Header>Error</Message.Header>
            <p>{fetchError}</p>
          </Message>
        ) : (
          <>
            {error && (
              <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{error}</p>
              </Message>
            )}
            <Grid stackable divided>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>Start Date</label>
                        <Input
                          type="date"
                          name="startDate"
                          value={formData.startDate}
                          onChange={(e, { name, value }) => {
                            handleChange(e, { name, value });
                            if (value) {
                              setIsEndDateDisabled(false);
                              setMinEndDate(value);
                              setFormData(prev => ({
                                ...prev,
                                endDate: '', // Reset End Date when Start Date changes
                              }));
                            } else {
                              setIsEndDateDisabled(true);
                              setMinEndDate('');
                            }
                          }}
                          min="2020-01-01"
                          max="2030-12-31"
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>End Date</label>
                        <Input
                          type="date"
                          name="endDate"
                          value={formData.endDate}
                          onChange={handleChange}
                          min={minEndDate}
                          disabled={isEndDateDisabled}
                          readOnly={false} // Users can select from calendar
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group widths="equal">
                      {/* Always display Employee Name and ID as read-only */}
                      <Form.Input
                        label="Employee Name"
                        placeholder="Employee Name"
                        name="employeeName"
                        value={formData.employeeName}
                        readOnly
                        required
                      />
                      <Form.Input
                        label="Employee ID"
                        placeholder="Employee ID"
                        name="employeeId"
                        value={formData.employeeId}
                        readOnly
                        required
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                    {/* Client Name Dropdown */}
                    <Form.Field required>
                      <label>Client Name</label>
                      <Dropdown
                        placeholder="Select Client"
                        fluid
                        search
                        selection
                        options={clients.map(client => ({
                          key: client.ClientID,
                          text: client.ClientName,
                          value: client.ClientID,
                        }))}
                        name="clientId"
                        value={formData.clientId}
                        onChange={handleChange}
                        clearable={!formData.projectId} // Allow clearing only if projectId is not set
                        disabled={!!formData.projectId} // Disable if projectId is set
                        upward={false}
                      />
                    </Form.Field>

                    {/* Project Name Dropdown */}
                    <Form.Field required>
                      <label>Project Name</label>
                      <Dropdown
                        placeholder="Select Project"
                        fluid
                        search
                        selection
                        options={getFilteredProjectOptions()}
                        name="projectId"
                        value={formData.projectId}
                        onChange={(e, { name, value }) => {
                          handleChange(e, { name, value });

                          if (!value) {
                            // Clear clientId when projectId is cleared
                            setFormData(prev => ({
                              ...prev,
                              clientId: '',
                            }));
                          }
                        }}
                        clearable
                        upward={false}
                      />
                    </Form.Field>
                  </Form.Group>

                    <Form.Group widths="equal"></Form.Group>
                  </Form>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Segment>
                    <Header as='h4' dividing>
                      Allocation %
                    </Header>
                    <div style={{ width: 150, height: 150, margin: '0 auto' }}>
                      <CircularProgressbar 
                        value={totalAllocation} 
                        text={`${totalAllocation}%`} 
                        styles={buildStyles({
                          textSize: '16px',
                          pathColor: '#3b82f6',
                          textColor: '#333',
                          trailColor: '#e2e8f0',
                        })}
                      />
                    </div>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>Time Sheet Approver</label>
                        <Dropdown
                          placeholder="Select Approver"
                          fluid
                          search
                          selection
                          options={getTimeSheetApproverOptions()} // Use updated function
                          name="timeSheetApprover"
                          value={formData.timeSheetApproverId}
                          onChange={handleChange}
                          clearable
                          upward
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>Allocation %</label>
                        <Dropdown
                          placeholder="Select Allocation %"
                          fluid
                          search // Enable search for consistency
                          selection
                          options={AllocationPercentOptions.filter(option => 
                            parseInt(option.value, 10) <= (remainingAllocation + currentAllocation)
                          )}
                          name="allocationPercent"
                          value={formData.allocationPercent}
                          onChange={handleChange}
                          onClick={handleDropdownClick} // Track dropdown click
                          upward={true}
                          clearable
                          style={allocationDropdownStyle} // Apply conditional styling
                        />
                        <div style={{ marginTop: '5px', color: 'gray', fontSize: '12px' }}>
                          Remaining Allocation: {remainingAllocation}%
                        </div>
                      </Form.Field>
                      <Form.Field required>
                        <label>Status</label>
                        <Dropdown
                          placeholder="Set Status"
                          fluid
                          search // Enable search for consistency
                          selection
                          options={[
                            { key: 'client-unallocated', text: 'Client Unallocated', value: 'Client Unallocated' },
                            { key: 'project-unallocated', text: 'Project Unallocated', value: 'Project Unallocated' },
                            { key: 'allocated', text: 'Allocated', value: 'Allocated' },
                            { key: 'closed', text: 'Closed', value: 'Closed' },
                          ]}
                          name="status"
                          value={formData.status}
                          onChange={handleChange}
                          clearable
                          upward={true}
                        />
                      </Form.Field>
                      
                    </Form.Group>
                    <Form.Group widths="equal">
                        {/* Billed Dropdown */}
                        <Form.Field required>
                        <label>Billed?</label>
                        <Dropdown
                          placeholder="Select Billed Check"
                          fluid
                          search
                          selection
                          options={billingOptions.billedCheck} // Uses BilledCheck options
                          name="billedCheck"
                          value={formData.billedCheck}
                          onChange={(e, { name, value }) => {
                            setFormData((prev) => {
                              // Automatically select the first valid billing type when "No" is selected
                              const defaultBillingType = value === 'No'
                                ? billingOptions.billingType.find(
                                    (option) => option.parentKey === value.toLowerCase()
                                  )?.value || '' // Default to empty if no matching option exists
                                : '';

                              return {
                                ...prev,
                                [name]: value,
                                billingType: defaultBillingType, // Update billingType dynamically
                              };
                            });
                          }}
                          clearable
                          upward
                        />
                      </Form.Field>


                        {/* Billing Type Dropdown */}
                        <Form.Field required>
                          <label>Billing Type</label>
                          <Dropdown
                            placeholder="Select Billing Type"
                            fluid
                            search
                            selection
                            options={billingOptions.billingType.filter(
                              option => option.parentKey === formData.billedCheck?.toLowerCase()
                            )} // Filters based on selected BilledCheck
                            name="billingType"
                            value={formData.billingType}
                            onChange={(e, { name, value }) => setFormData(prev => ({
                              ...prev,
                              [name]: value,
                            }))}
                            disabled={!formData.billedCheck} // Disable until BilledCheck is selected
                            clearable
                            upward
                          />
                        </Form.Field>
                      </Form.Group>

                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          color="blue" 
          onClick={handleSubmit} 
          disabled={!isFormValid() || loading} // No state updates within isFormValid
        >
          {allocationData ? 'Update' : 'Save'}
        </Button>
      </Modal.Actions>
      <ToastContainer/>
    </Modal>
  );
};

// Define PropTypes for better type checking
AllocationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  employeeData: PropTypes.shape({
    EmployeeName: PropTypes.string,
    EmployeeId: PropTypes.string,
  }), // Optional: Object containing EmployeeName and EmployeeID
  clientProjectData: PropTypes.shape({
    clientId: PropTypes.number,
    projectId: PropTypes.number,
  }), // Optional: Object containing ClientID and ProjectID
  allocationData: PropTypes.shape({
    AllocationID: PropTypes.number,
    ClientID: PropTypes.number,
    ProjectID: PropTypes.number,
    AllocationStatus: PropTypes.string,
    allocationPercentDropdown: PropTypes.number,
    AllocationPercent: PropTypes.number,
    AllocationStartDate: PropTypes.string,
    AllocationEndDate: PropTypes.string,
    AllocationTimeSheetApprover: PropTypes.string,
    AllocationBillingType: PropTypes.string,
    AllocationBilledCheck: PropTypes.string,
    ModifiedBy: PropTypes.string,
    ModifiedAt: PropTypes.string,
    AllocationTimeSheetApproverRole: PropTypes.arrayOf(PropTypes.string), // Added for roles
  }), // Allocation details or null
  userRole: PropTypes.string.isRequired,
};

export default AllocationModal;
