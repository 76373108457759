// File: src/pages/BizopsDashboard/BizopsDashboard.js

import React, { useEffect, useState, useContext, useRef } from 'react';
import { Table, Icon, Input, Button, Message, Dropdown, Checkbox, Progress, Modal } from 'semantic-ui-react';
import ViewCard from '../../components/ViewCards/Viewcard';
import { useNavigate } from 'react-router-dom';
import './BizopsDashboard.css';
import * as XLSX from 'xlsx';
import { ProfileContext } from '../../context/ProfileContext';
import AllocationsTableUpload from '../../components/ExcelPreviewModal/AllocationsTableUpload'; // Adjust the path as necessary
import { convertExcelDateToJSDate } from '../../utils/dateUtils'; // Adjust the path as necessary
import { UserContext } from '../../context/UserContext'; // Adjust the path as necessary
import { ROLES } from '../../constants/roles';
import useAxios from '../../axiosSetup';
import debounce from 'lodash.debounce';
import { CONFIG } from '../../config/config'; // Import the configuration

// Column Labels Mapping
const columnLabels = {
  'AllocationID': 'Allocation ID',
  'EmployeeID': 'Employee ID',
  'EmployeeName': 'Employee Name',
  'EmployeeEmail': 'Email', // Standardized to 'Email'
  'EmployeeLocation': 'Employee Location',
  'EmployeeContractType': 'Contract Type',
  'EmployeeJoiningDate': 'Joining Date',
  'EmployeeEndingDate': 'Ending Date',
  'EmployeeStudio': 'Studio',
  'EmployeeSubStudio': 'Sub Studio',
  'EmployeeRole': 'Role',
  'EmployeeTYOE': 'Type of Employment',
  'EmployeeHCMStatus': 'HCM Status',
  'ClientID': 'Client ID',
  'ClientName': 'Client Name',
  'ClientPartner': 'Client Partner',
  'ProjectID': 'Project ID',
  'ProjectName': 'Project Name',
  'ProjectManager': 'Project Manager',
  'AllocationStatus': 'Allocation Status',
  'AllocationPercent': 'Allocation %',
  'AllocationBillingType': 'Billing Type',
  'AllocationBilledCheck': 'Billed Check',
  'AllocationTimeSheetApprover': 'Timesheet Approver',
  'AllocationTimeSheetApproverID': 'Timesheet Approver ID',
  'AllocationStartDate': 'Start Date',
  'AllocationEndDate': 'End Date',
  'ModifiedBy': 'Modified By',
  'ModifiedAt': 'Modified At'
};

// Helper function to generate page numbers with ellipsis
const generatePageNumbers = (totalPages, currentPage) => {
  const pages = [];

  if (totalPages <= 7) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    // Always show first two pages
    pages.push(1, 2);

    if (currentPage > 4) {
      pages.push('ellipsis1');
    }

    const start = Math.max(3, currentPage - 1);
    const end = Math.min(totalPages - 2, currentPage + 1);

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    if (currentPage < totalPages - 3) {
      pages.push('ellipsis2');
    }

    // Always show last two pages
    pages.push(totalPages - 1, totalPages);
  }

  return pages;
};

// Updated ColumnToggle component with multiple selection capability
const ColumnToggle = ({ columns, visibleColumns, onToggle }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (visibleColumns.length === columns.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [visibleColumns, columns.length]);

  const handleSelectAll = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent dropdown from closing
    if (selectAll) {
      onToggle('deselectAll');
    } else {
      onToggle('selectAll');
    }
    // No need to manually toggle selectAll here as useEffect handles it based on visibleColumns
  };

  const handleCheckboxChange = (e, column) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent dropdown from closing
    onToggle(column);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <Dropdown
      text='Toggle Columns'
      icon='columns'
      floating
      labeled
      button
      className='icon'
      aria-label="Toggle Columns Dropdown"
      closeOnChange={false} // Prevent dropdown from closing on change
      closeOnBlur={false}   // Prevent dropdown from closing on blur
      open={isDropdownOpen}
      onOpen={handleDropdownToggle}
      onClose={handleDropdownToggle}
      aria-expanded={isDropdownOpen.toString()}
    >
      <Dropdown.Menu style={{ 
        maxHeight: '300px',  // Set your desired height
        overflowY: 'auto',    // Enable vertical scrollbar
        overflowX: 'hidden',  // Prevent horizontal scrollbar
      }}>
        {/* Select All / Deselect All Option */}
        <Dropdown.Item as='div' onClick={handleSelectAll}>
          <Checkbox
            label={selectAll ? 'Deselect All' : 'Select All'}
            checked={selectAll}
            onChange={handleSelectAll}
            toggle
            style={{ marginRight: '10px' }}
            aria-label={selectAll ? 'Deselect All Columns' : 'Select All Columns'}
          />
        </Dropdown.Item>
        <Dropdown.Divider />
        {/* Individual Column Toggles */}
        {columns.map(column => (
          <Dropdown.Item as='div' key={column}>
            <Checkbox
              label={columnLabels[column] || column}
              checked={visibleColumns.includes(column)}
              onChange={(e) => handleCheckboxChange(e, column)}
              style={{ marginRight: '10px' }}
              aria-label={`Toggle ${columnLabels[column] || column} Column`}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const BizopsDashboard = () => {
  const { userRole } = useContext(UserContext);
  const axiosInstance = useAxios();
  const { displayName } = useContext(ProfileContext);
  const navigate = useNavigate();

  // State variables for counts
  const [todo, setTodo] = useState(0); // Unallocated Employees
  const [draft, setDraft] = useState(0); // Draft Employees
  const [totalemp, setTotalEmp] = useState(0); // Total Employees
  const [activeProjects, setActiveProjects] = useState(0); // Active Projects

  // Loading and error states for counts
  const [countsLoading, setCountsLoading] = useState(true);
  const [countsError, setCountsError] = useState(null);

  // State variables for allocations
  const [currentDate, setCurrentDate] = useState('');
  const [allocatedEmployees, setAllocatedEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(100);
  const [totalRecords, setTotalRecords] = useState(0); // New state for total records

  // State for EmployeesTableUpload
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);

  // Ref for the hidden file input
  const hiddenFileInput = useRef(null);

  // Handle file upload using ref
  const handleUploadButtonClick = () => {
    hiddenFileInput.current.click();
  };
  
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Read data with cellDates option to better handle dates
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          defval: '',
          raw: false,
          dateNF: 'YYYY-MM-DD',
          cellDates: true,
        });

        // Convert date fields
        const processedData = jsonData.map((record) => ({
          ...record,
          AllocationStartDate: convertExcelDateToJSDate(record.AllocationStartDate),
          AllocationEndDate: convertExcelDateToJSDate(record.AllocationEndDate),
          EmployeeJoiningDate: convertExcelDateToJSDate(record.EmployeeJoiningDate),
          EmployeeEndingDate: convertExcelDateToJSDate(record.EmployeeEndingDate),
          // Add any other date fields that need conversion
        }));

        console.log('Processed Data:', processedData);

        setUploadedData(processedData);
        setIsPopupOpen(true); // Open the modal with the uploaded data
      };
      reader.readAsBinaryString(file);
      e.target.value = null;
    }
  };

  // Handle modal close
  const handleModalClose = () => {
    setIsPopupOpen(false);
    setUploadedData([]);
  };
  
  // New state for visible columns
  const [visibleColumns, setVisibleColumns] = useState([
    'AllocationID', 'EmployeeID', 'EmployeeName', 'EmployeeEmail', 'EmployeeLocation',
    'ClientName', 'ProjectName', 'AllocationStatus', 'AllocationPercent',
    'AllocationTimeSheetApproverID' // Added to default visible columns
  ]);

  // All available columns
  const allColumns = [
    'AllocationID', 'EmployeeID', 'EmployeeName', 'EmployeeEmail', 'EmployeeLocation',
    'EmployeeContractType', 'EmployeeJoiningDate', 'EmployeeEndingDate',
    'EmployeeStudio', 'EmployeeSubStudio', 'EmployeeRole', 'EmployeeTYOE',
    'EmployeeHCMStatus', 'ClientID', 'ClientName', 'ClientPartner',
    'ProjectID', 'ProjectName', 'ProjectManager', 'AllocationStatus',
    'AllocationPercent', 'AllocationBillingType', 'AllocationBilledCheck',
    'AllocationTimeSheetApprover', 'AllocationTimeSheetApproverID', // Added this field
    'AllocationStartDate',
    'AllocationEndDate', 'ModifiedBy', 'ModifiedAt'
  ];

  // Function to toggle column visibility
  const toggleColumn = (actionOrColumn) => {
    if (actionOrColumn === 'selectAll') {
      setVisibleColumns(allColumns);
    } else if (actionOrColumn === 'deselectAll') {
      setVisibleColumns([]);
    } else {
      setVisibleColumns(prev =>
        prev.includes(actionOrColumn)
          ? prev.filter(col => col !== actionOrColumn)
          : [...prev, actionOrColumn]
      );
    }
  };

  // Fetch Counts from Backend
  const fetchCounts = async () => {
    setCountsLoading(true);
    setCountsError(null);

    try {
      const response = await axiosInstance.get(`/api/bizops/card`);
      const data = response.data;

      setTotalEmp(data.totalEmployees);
      setTodo(data.unallocatedEmployees);
      setDraft(data.draftEmployees);
      setActiveProjects(data.activeProjects);
      setCountsLoading(false);
    } catch (err) {
      console.error('Error fetching counts:', err);
      setCountsError('Failed to fetch counts. Please try again later.');
      setCountsLoading(false);
    }
  };

  // New state variables for date filters
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: ''
  });
  const [isEndDateDisabled, setIsEndDateDisabled] = useState(true);
  const [minEndDate, setMinEndDate] = useState('');

  // New state variables for error handling
  const [error, setError] = useState(null);

  // Handle form input changes
  const handleChange = (e, { name, value }) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'startDate') {
      if (value) {
        setIsEndDateDisabled(false);
        setMinEndDate(value);
        setFormData(prev => ({
          ...prev,
          endDate: '', // Reset End Date when Start Date changes
        }));
        setCurrentPage(1); // Reset to first page
        debouncedFetchAllocations(1, rowsPerPage, searchTerm, value, formData.endDate); // Fetch with new start date
      } else {
        setIsEndDateDisabled(true);
        setMinEndDate('');
        setFormData(prev => ({
          ...prev,
          endDate: '',
        }));
        setAllocatedEmployees([]); // Optionally reset allocations when start date is cleared
      }
    }

    if (name === 'endDate' && value) {
      // Optional: Validate that endDate is not before startDate
      if (formData.startDate && value < formData.startDate) {
        setError('End Date cannot be before Start Date.');
        return;
      } else {
        setError(null);
        // Fetch allocations with new end date
        debouncedFetchAllocations(currentPage, rowsPerPage, searchTerm, formData.startDate, value);
      }
    }
  };

  // Debounced fetchAllocations to prevent excessive API calls
  const debouncedFetchAllocations = useRef(
    debounce((page, pageSize, search, startDate, endDate) => {
      fetchAllocations(page, pageSize, search, startDate, endDate);
    }, 500)
  ).current;

  useEffect(() => {
    const { startDate, endDate } = formData;
    if (startDate && endDate) {
      fetchAllocations(currentPage, rowsPerPage, searchTerm, startDate, endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.startDate, formData.endDate]);

  // Fetch allocations with pagination, search, and sorting
  const fetchAllocations = async (page, pageSize, search, startDate, endDate) => {
    setLoading(true);
    setError(null);

    try {
      const params = {
        page,
        pageSize,
        searchTerm: search,
        sortColumn: sortColumn || 'AllocationStartDate', // Default sort column
        sortDirection: sortDirection || 'desc' // Default sort direction
      };
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;

      const response = await axiosInstance.get(`/api/master-allocations`, { params });
      const data = response.data;

      setAllocatedEmployees(data.masterAllocations);
      setTotalRecords(data.totalRecords);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching master allocations:', err);
      setError('Failed to fetch allocations. Please try again later.');
      setLoading(false);
    }
  };

  // Navigation Handlers
  const handleUnallocatedClick = () => {
    navigate('/allocations/employees', { state: { filter: 'unallocated' } });
  };

  const handleDraftClick = () => {
    navigate('/allocations/employees', { state: { filter: 'draft' } });
  };

  const handleEmployeeClick = () => {
    navigate('/allocations/employees', { state: { filter: 'all' } });
  };

  // Fetch counts on component mount
  useEffect(() => {
    fetchCounts();

    // Set current date
    const today = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);
    setCurrentDate(formattedDate);

    // Calculate first day of the current month
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const formattedFirstDay = firstDay.toISOString().split('T')[0]; // YYYY-MM-DD

    // Format today's date as YYYY-MM-DD
    const formattedToday = today.toISOString().split('T')[0];

    // Set formData with default date range
    setFormData({
      startDate: formattedFirstDay,
      endDate: formattedToday
    });

    // Enable End Date input
    setIsEndDateDisabled(false);
    setMinEndDate(formattedFirstDay);

    // Fetch allocations for the default date range
    fetchAllocations(1, rowsPerPage, '', formattedFirstDay, formattedToday);
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Fetch allocations whenever currentPage, sortColumn, or sortDirection changes
    if (formData.startDate && formData.endDate) {
      fetchAllocations(currentPage, rowsPerPage, searchTerm, formData.startDate, formData.endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortColumn, sortDirection]);

  // Handle search with debounce
  const handleSearchChange = (e) => {
    const searchValue = e.target.value.trim().toLowerCase();
    setSearchTerm(searchValue);
    setCurrentPage(1); // Reset to first page on new search
    debouncedFetchAllocations(1, rowsPerPage, searchValue, formData.startDate, formData.endDate);
  };

  // Handle sorting - Server-side sorting implementation
  const handleSort = (column) => {
    let direction = 'asc';
    if (sortColumn === column && sortDirection === 'asc') {
      direction = 'desc';
    }
    setSortColumn(column);
    setSortDirection(direction);
    setCurrentPage(1); // Reset to first page on sort
    fetchAllocations(1, rowsPerPage, searchTerm, formData.startDate, formData.endDate);
  };

  // Function to render sort icon
  const renderSortIcon = (column) => {
    if (sortColumn !== column) return null;
    if (sortDirection === 'ascending' || sortDirection === 'asc') {
      return <Icon name="sort up" />;
    } else {
      return <Icon name="sort down" />;
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  // Handle page change
  const paginate = (pageNumber) => {
    if (pageNumber === 'ellipsis1' || pageNumber === 'ellipsis2') return;
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  // Generate pagination numbers with ellipsis
  const paginationPages = generatePageNumbers(totalPages, currentPage);

  // Define the batch size from configuration
  const batchSize = CONFIG.batchSize; // Use the batch size from configuration

  // State variables for download progress
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  // Download Excel
  const downloadExcel = async () => {
    if (totalRecords === 0) {
      alert('No data available to download.');
      return;
    }

    // Calculate total batches
    const totalBatches = Math.ceil(totalRecords / batchSize);
    const batches = [];
    for (let i = 1; i <= totalBatches; i++) {
      batches.push(i);
    }

    // Initialize variables to accumulate data
    let allData = [];

    // Function to fetch a single batch
    const fetchBatch = async (batchNumber) => {
      const params = {
        page: batchNumber,
        pageSize: batchSize,
        searchTerm: searchTerm,
        sortColumn: sortColumn || 'AllocationStartDate',
        sortDirection: sortDirection || 'desc'
      };
      if (formData.startDate) params.startDate = formData.startDate;
      if (formData.endDate) params.endDate = formData.endDate;

      try {
        const response = await axiosInstance.get(`/api/master-allocations`, { params });
        const data = response.data.masterAllocations;
        return data;
      } catch (err) {
        console.error(`Error fetching batch ${batchNumber}:`, err);
        throw err;
      }
    };

    try {
      setIsDownloading(true);
      setDownloadProgress(0);

      // Fetch all batches sequentially to prevent overwhelming the server
      for (let i = 1; i <= totalBatches; i++) {
        const batchData = await fetchBatch(i);
        allData = allData.concat(batchData);
        setDownloadProgress(Math.round((i / totalBatches) * 100));
      }

      // Map the data to include all columns
      const dataToExport = allData.map((emp) => {
        const exportedData = {};
        allColumns.forEach((column) => {
          let value = emp[column];
          if (column === 'AllocationBilledCheck') {
            value = emp[column] ? 'Yes' : 'No';
          } else if (column === 'ModifiedAt') {
            value = new Date(emp[column]).toLocaleString();
          } else if (['EmployeeJoiningDate', 'EmployeeEndingDate', 'AllocationStartDate', 'AllocationEndDate'].includes(column)) {
            value = new Date(emp[column]).toLocaleDateString();
          }
          exportedData[columnLabels[column] || column] = value;
        });
        return exportedData;
      });

      // Create a worksheet
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);

      // Create a workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Allocations');

      // Generate Excel file and trigger download
      XLSX.writeFile(workbook, 'allocations.xlsx');

      setIsDownloading(false);
      setDownloadProgress(0);
    } catch (err) {
      console.error('Error downloading Excel:', err);
      alert('Failed to download Excel. Please try again later.');
      setIsDownloading(false);
      setDownloadProgress(0);
    }
  };

  return (
    <div className="main-layout">
      <div className='right-content'>
        {/* Greeting and Cards */}
        <>
          <div className='top-content'>
            <div className='greeting'>
              <h1>Hello {displayName},</h1>
              <h2>{currentDate}</h2>
            </div>
          </div>

          <div className='bottom-content-cards'>
            <div className='cards'>
              <ViewCard
                icon="fa-user-tie"
                header="Employees"
                value={countsLoading ? 'Loading...' : totalemp}
                onClick={handleEmployeeClick}
              />
            </div>
            <div className='cards'>
              <ViewCard
                icon="fa-tasks"
                header="Projects"
                value={countsLoading ? 'Loading...' : activeProjects}
                onClick={() => navigate('/allocations/projects')}
              />
            </div>
            <div className='cards'>
              <ViewCard
                icon="fa-user-clock"
                header="Unallocated"
                value={countsLoading ? 'Loading...' : todo}
                onClick={handleUnallocatedClick}
              />
            </div>
            <div className='cards'>
              <ViewCard
                icon="fa-file-alt"
                header="Drafts"
                value={countsLoading ? 'Loading...' : draft}
                onClick={handleDraftClick}
              />
            </div>
          </div>

          {/* Display error message if counts failed to load */}
          {countsError && (
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p>{countsError}</p>
            </Message>
          )}
        </>

        {/* Allocations Section */}
        <div className='last-edited'>
          <h2>Allocations</h2>

          {/* Search and Download Controls */}
          <div className='table-filter-layout'>
            {/* Date Filters and Search/Download */}
            <div className='filter-tabs'>
              {/* Start Date Input */}
              <Input
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                placeholder="Start Date"
                min="2020-01-01"
                max="2030-12-31"
                aria-label="Start Date"
              />
              -
              {/* End Date Input */}
              <Input
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                min={minEndDate}
                disabled={isEndDateDisabled}
                placeholder="End Date"
                aria-label="End Date"
              />
            </div>
            <div className='search-download-container'>
              
              {/* Search Input */}
              <Input
                icon="search"
                className='search-bar'
                placeholder="Search by name, email, or ID..."
                value={searchTerm}
                onChange={handleSearchChange}
                aria-label="Search Employees"
                style={{ marginRight: '10px', width: '300px' }}
              />
              
              {/* Download Button */}
              <Button
                icon
                className='download-button'
                labelPosition='left'
                color='blue'
                onClick={downloadExcel}
                aria-label="Download Employees as Excel"
                disabled={isDownloading} // Disable button during download
              >
                <Icon name='download' />
                Download
              </Button>
              <ColumnToggle
                className='toggle-columns'
                columns={allColumns}
                visibleColumns={visibleColumns}
                onToggle={toggleColumn}
              />

              {userRole === ROLES.ADMIN && (
                <>
                  {/* Upload Button */}
                  <Button
                    icon
                    labelPosition="left"
                    color="green"
                    className="upload-button"
                    aria-label="Upload Excel File"
                    onClick={handleUploadButtonClick}
                  >
                    <Icon name="upload" />
                    Upload
                  </Button>
                </>
              )}

              {/* Hidden File Input */}
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
              />

            </div>
          </div>

          {/* Display error message if any */}
          {error && (
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p>{error}</p>
            </Message>
          )}

          {/* Allocation Table */}
          <div className='table'>
            {loading ? (
              <div className="loader">
                <Icon loading name='spinner' /> Loading...
              </div>
            ) : (
              <>
                <Table celled striped sortable>
                  <Table.Header>
                    <Table.Row>
                      {visibleColumns.map(column => (
                        <Table.HeaderCell 
                          key={column} 
                          onClick={() => handleSort(column)}
                          sorted={sortColumn === column ? sortDirection : null}
                          aria-sort={sortColumn === column ? sortDirection : 'none'}
                        >
                          {columnLabels[column] || column} {renderSortIcon(column)}
                        </Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {allocatedEmployees.length > 0 ? (
                      allocatedEmployees.map((employee) => (
                        <Table.Row key={employee.AllocationID}>
                          {visibleColumns.map(column => (
                            <Table.Cell key={column}>
                              {column === 'AllocationBilledCheck'
                                ? (employee[column] ? 'Yes' : 'No')
                                : column === 'ModifiedAt'
                                ? new Date(employee[column]).toLocaleString()
                                : ['EmployeeJoiningDate', 'EmployeeEndingDate', 'AllocationStartDate', 'AllocationEndDate'].includes(column)
                                ? new Date(employee[column]).toLocaleDateString()
                                : employee[column]}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan={visibleColumns.length} textAlign="center">
                          No allocations found matching the criteria.
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>

                {/* Custom Pagination Controls */}
                {totalPages > 1 && (
                  <div className="pagination">
                    <button
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="pagination-button"
                      aria-label="Previous Page"
                    >
                      Back
                    </button>

                    {paginationPages.map((page, index) => (
                      page === 'ellipsis1' || page === 'ellipsis2' ? (
                        <span key={index} className="pagination-ellipsis">...</span>
                      ) : (
                        <button
                          key={page}
                          onClick={() => paginate(page)}
                          className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                          aria-label={`Page ${page}`}
                        >
                          {page}
                        </button>
                      )
                    ))}

                    <button
                      onClick={() => paginate(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="pagination-button"
                      aria-label="Next Page"
                    >
                      Next
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* EmployeesTableUpload Component */}
        <AllocationsTableUpload
          open={isPopupOpen}
          onClose={handleModalClose}
          data={uploadedData}
        />

        {/* Download Progress Modal */}
        <Modal
          open={isDownloading}
          size='small'
          dimmer='blurring'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          aria-labelledby="download-progress-modal"
        >
          <Modal.Header id="download-progress-modal">Downloading Data</Modal.Header>
          <Modal.Content>
            <Progress percent={downloadProgress} indicating progress>
              {`Downloading ${downloadProgress}%...`}
            </Progress>
          </Modal.Content>
        </Modal>
      </div>
    </div>
  );
};

export default BizopsDashboard;
