export const Messages = {
  allocationBelow50Warning: 'Resource allocation is below 50%',
  employeeDataMissing: "Employee data is missing.",
  noChangesDetected: "No changes detected in the timesheet.",
  submitFailed: "Failed to submit timesheet. Please try again later.",
  submitError: "An error occurred while submitting the timesheet.",
  hoursExceeded: (projectName, day) => 
    `Cannot set more than 24 hours for project "${projectName}" on ${day}.`,
  allocationExceeded: (projectName, allocationHours, day) => 
    `You have exceeded the allocated ${allocationHours} hours for project "${projectName}" on ${day}.`,

  submitSuccess: (projectName, date) => 
    `Timesheet for ${projectName} on ${date} submitted successfully.`,
  submitFailureWithError: (projectName, date, message) => 
    `Failed to submit timesheet for ${projectName} on ${date}: ${message}`,
}; 