// src/components/ManagerView.js

import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import {
  Container,
  Header,
  Grid,
  Segment,
  Table,
  Button,
  Accordion,
  Icon,
  Label,
  Checkbox,
  Loader,
  Input,
  Dimmer,
  Message,
  Popup,
  Dropdown,
  Search,
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProfileContext } from '../../context/ProfileContext';
import useAxios from '../../axiosSetup'; // Import your custom useAxios hook
import EmployeeAllocationsManualUpload from '../../components/EmployeeAllocationsManualUpload/EmployeeAllocationsManualUpload'; // Import the new component
import DatePicker from 'react-datepicker'; // New import
import 'react-datepicker/dist/react-datepicker.css'; // New import


const formatMonth = (date) => {
  return date.toLocaleString('default', { month: 'long', year: 'numeric' });
};
// Approval Status Mapping
const APPROVAL_STATUS = {
  0: "Not Submitted",
  1: "Submitted",
  2: "Approved",
  3: "Rejected",
};

// Color Mapping for Approval Status
const APPROVAL_STATUS_COLOR = {
  0: "grey",      // Not Submitted
  1: "yellow",    // Submitted
  2: "green",     // Approved
  3: "red",       // Rejected
};

// Helper function to get ordinal suffix for dates
const getOrdinalSuffix = (date) => {
  const j = date % 10,
        k = date % 100;
  if (j === 1 && k !== 11) {
    return date + "st";
  }
  if (j === 2 && k !== 12) {
    return date + "nd";
  }
  if (j === 3 && k !== 13) {
    return date + "rd";
  }
  return date + "th";
};

const rejectionReasons = [
  { key: 'hours_exceed_limit', value: 'Hours Exceed Allowed Limit', text: 'Hours Exceed Allowed Limit' },
  { key: 'incomplete_hours', value: 'Incomplete Hours Entered', text: 'Incomplete Hours Entered' },
  { key: 'unapproved_overtime', value: 'Unapproved Overtime', text: 'Unapproved Overtime' },
  { key: 'unapproved_leave', value: 'Unapproved Leave', text: 'Unapproved Leave' },
  { key: 'holiday_on_working_day', value: 'Holiday Marked on a Working Day', text: 'Holiday Marked on a Working Day' },
];

// Helper function to format dates
const formatDate = (date) => {
  const day = getOrdinalSuffix(date.getDate());
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear().toString().slice(-2);
  return `${day} ${month} ${year}`;
};

// Update the getStartOfWeek function with more precise handling
const getStartOfWeek = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0); // Reset time part
  const day = d.getDay();
  
  // Calculate days to subtract to get to Monday
  // If Sunday (0), subtract 6 days
  // If Monday (1), subtract 0 days
  // If Tuesday (2), subtract 1 day, etc.
  const daysToSubtract = day === 0 ? 6 : day - 1;
  
  d.setDate(d.getDate() - daysToSubtract);
  console.log('Start of week:', d.toISOString());
  return d;
};

// Update getSelectedDates with explicit date handling
const getSelectedDates = (weekOffset) => {
  const today = new Date();
  today.setDate(today.getDate() + (weekOffset * 7));
  const weekStart = getStartOfWeek(today);
  
  console.log('Week offset:', weekOffset);
  console.log('Week start:', weekStart.toISOString());
  
  // Generate dates from Monday to Sunday
  const dates = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(weekStart);
    date.setDate(weekStart.getDate() + i);
    const formattedDate = formatDateStr(date);
    console.log(`Day ${i + 1}:`, formattedDate);
    return formattedDate;
  });
  
  return dates;
};

// Update getWeekDateRange with explicit date handling
const getWeekDateRange = (weekOffset) => {
  const today = new Date();
  today.setDate(today.getDate() + (weekOffset * 7));
  const weekStart = getStartOfWeek(today);
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6); // Add 6 days to get to Sunday
  
  console.log('Week range:', {
    start: weekStart.toISOString(),
    end: weekEnd.toISOString()
  });
  
  return {
    startDate: weekStart,
    endDate: weekEnd
  };
};

// Helper function to format date as YYYY-MM-DD with timezone handling
const formatDateStr = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const ManagerView = () => {
  const [currentDate, setCurrentDate] = useState('');
  const [managerDetails, setManagerDetails] = useState(null); // State for manager details
  const [employeeData, setEmployeeData] = useState(null); // State for employee details and clients
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [timesheetManualUpload, setTimesheetManualUpload] = useState(0); // New state for TimesheetManualUpload
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);
  const [timesheetComments, setTimesheetComments] = useState({});
  const [selectedTimesheets, setSelectedTimesheets] = useState({}); // { employeeId: Set(guidId) }
  const [employees, setEmployees] = useState([]); // Manage employees in state
  const [sortConfig, setSortConfig] = useState({}); // { employeeId: { column: 'columnName', direction: 'ascending' | 'descending' } }
  const [filter, setFilter] = useState('all'); // Filter state
  const [weekOffset, setWeekOffset] = useState(0); // New state for week offset
  const profile = useContext(ProfileContext); // Access ProfileContext
  const [selectedRejectionReasons, setSelectedRejectionReasons] = useState({});
  const weekRange = useMemo(() => getWeekDateRange(weekOffset), [weekOffset]);
  const [dateFilterType, setDateFilterType] = useState('week'); // 'week', 'month', 'custom'
  const [monthOffset, setMonthOffset] = useState(0); // New for month-wise
  const [customStartDate, setCustomStartDate] = useState(null); // New for custom
  const [customEndDate, setCustomEndDate] = useState(null); // New for custom
  const [selectedRange, setSelectedRange] = useState('week');
  const [selectedEmployees, setSelectedEmployees] = useState([]); // New state for selected employees
  const [customDates, setCustomDates] = useState({
    startDate: null,
    endDate: null
  });
  const [customDateRange, setCustomDateRange] = useState({
    startDate: '',
    endDate: ''
  });
  const [dayOffset, setDayOffset] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const axiosInstance = useAxios(); // Initialize axiosInstance

  // 1. Consolidate the date-related dependencies into a single object
  const dateConfig = useMemo(() => ({
    type: dateFilterType,
    dayOffset,
    weekOffset,
    monthOffset,
    customStartDate,
    customEndDate
  }), [dateFilterType, dayOffset, weekOffset, monthOffset, customStartDate, customEndDate]);

  // Set current date
  useEffect(() => {
    const today = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);
    setCurrentDate(formattedDate);
  }, []);

  // 2. Update the useEffect dependencies for manager projects
  useEffect(() => {
    fetchManagerProjects();
    // Remove individual date-related dependencies and use the consolidated dateConfig
  }, [profile.userPrincipalName, dateConfig]);

  // 3. Update the useEffect for employee fetching
  useEffect(() => {
    fetchEmployees();
    // Remove date-related dependencies since they'll be handled by the manager projects fetch
  }, [selectedProjectId, managerDetails, filter]);

  // Function to fetch manager projects
  const fetchManagerProjects = async () => {
    try {
      setLoading(true);
      setError(null);
  
      const managerEmail = profile.userPrincipalName;
      if (!managerEmail) {
        throw new Error("User email is not available.");
      }
  
      // Get dates based on current dateConfig
      const selectedDates = getSelectedDates();
  
      console.log(`Fetching manager projects for email: ${managerEmail}, Dates: ${selectedDates.join(',')}`);
  
      const response = await axiosInstance.get(`/api/getManagerProjects/`, {
        params: { 
          email: managerEmail,
          dates: selectedDates.join(',')
        }
      });
  
      if (response.data.status) {
        setEmployeeData(response.data.data);
        setManagerDetails(response.data.data.employeeDetails); // Set manager details
  
        // Preserve selectedClientId if already set
        if (!selectedClientId && response.data.data.clients.length > 0) {
          setSelectedClientId(response.data.data.clients[0].ClientID);
        }
  
        // Preserve selectedProjectId if already set and it's part of the new projects
        if (selectedClientId) {
          const currentClient = response.data.data.clients.find(client => client.ClientID === selectedClientId);
          if (currentClient && currentClient.Projects.length > 0) {
            if (!selectedProjectId) {
              setSelectedProjectId(currentClient.Projects[0].ProjectID);
              setTimesheetManualUpload(currentClient.Projects[0].TimesheetManualUpload);
            } else {
              // Check if the currentProjectId exists in the fetched projects
              const currentProject = currentClient.Projects.find(project => project.ProjectID === selectedProjectId);
              if (currentProject) {
                setTimesheetManualUpload(currentProject.TimesheetManualUpload);
              } else {
                // If currentProjectId no longer exists, reset to first project
                setSelectedProjectId(currentClient.Projects[0].ProjectID);
                setTimesheetManualUpload(currentClient.Projects[0].TimesheetManualUpload);
              }
            }
          }
        }
      } else {
        throw new Error(response.data.message || "Failed to retrieve data.");
      }
  
    } catch (err) {
      console.error('Error fetching manager projects:', err);
      setError(err.message || 'Failed to load data.');
      toast.error(`Error fetching manager projects: ${err.message || 'Failed to load data.'}`);
    } finally {
      setLoading(false);
    }
  };
  
  // Function to fetch employees (without timesheet details)
  // Define a mapping from filter to approvalStatus
const FILTER_MAPPING = {
  'all': null,
  'not-submitted': 0,
  'submitted': 1,
  'approved': 2,
  'rejected': 3,
};

const fetchEmployees = async () => {
  if (selectedProjectId && managerDetails) {
    try {
      setLoading(true);
      setError(null);

      const managerEmployeeId = managerDetails.EmployeeId;
      if (!managerEmployeeId) {
        throw new Error("Manager EmployeeId is not available.");
      }

      const selectedDates = getSelectedDates();

      // Prepare query parameters
      const params = { dates: selectedDates.join(',') };

      // Include approvalStatus if filter is not 'all'
      if (filter !== 'all') {
        params.approvalStatus = FILTER_MAPPING[filter];
      }

      console.log(`Fetching employee allocations with params:`, params);

      const endpoint = `/api/timesheet/approver/${managerEmployeeId}/project/${selectedProjectId}`;

      const response = await axiosInstance.get(endpoint, { params });

      if (response.status === 200 && response.data.employees) {
        const fetchedEmployees = response.data.employees;

        // Map API response to frontend state without timesheet details
        const mappedEmployees = fetchedEmployees.map(emp => ({
          id: emp.EmployeeId, // Unique identifier
          employeeId: emp.EmployeeId,
          name: emp.EmployeeName,
          email: emp.EmployeeEmail,
          role: emp.EmployeeRole,
          workingStatus: emp.EmployeeHCMStatus,
          allocationId: emp.AllocationID,
          allocatedHours: emp.allocatedHours,
          workedHours: emp.workedHours,
          workedPercentage: emp.workedPercentage,
          holidays: emp.holidays,
          leaves: emp.leaves,
          timesheet: [], // Initialize as empty array
          timesheetLoading: false, // Initialize loading state
        }));

        setEmployees(mappedEmployees);

        // Parallel fetching of timesheet details for all employees
        await fetchAllTimesheetDetails(mappedEmployees);
      } else {
        throw new Error("Failed to retrieve employee data.");
      }

    } catch (error) {
      console.error('Error fetching employee data:', error);
      setError(error.response?.data?.error || error.message || 'Failed to load employee data.');
      toast.error(`Error fetching employee data: ${error.response?.data?.error || error.message || 'Failed to load employee data.'}`);
    } finally {
      setLoading(false);
    }
  }
};

// New Function to fetch timesheet details for all employees
const fetchAllTimesheetDetails = async (employeesList) => {
  try {
    const managerEmployeeId = managerDetails.EmployeeId;
    const projectId = selectedProjectId;
    const selectedDates = getSelectedDates();
    const BATCH_SIZE = 50; // Number of allocation IDs per batch

    // Collect all allocation IDs
    const allocationIds = employeesList.map(employee => employee.allocationId);

    // Split allocation IDs into batches
    const batches = [];
    for (let i = 0; i < allocationIds.length; i += BATCH_SIZE) {
      batches.push(allocationIds.slice(i, i + BATCH_SIZE));
    }

    // Process each batch
    const allTimesheetData = [];
    for (const batchIds of batches) {
      const endpoint = `/api/timesheet/approver/${managerEmployeeId}/project/${projectId}/details`;
      const response = await axiosInstance.get(endpoint, {
        params: { 
          allocationId: batchIds.join(','),
          dates: selectedDates.join(',')
        }
      });

      if (response.data.timesheet) {
        allTimesheetData.push(...response.data.timesheet);
      }
    }

    // Group all timesheet entries by AllocationId
    const timesheetsByAllocation = allTimesheetData.reduce((acc, entry) => {
      if (!acc[entry.AllocationId]) {
        acc[entry.AllocationId] = [];
      }
      acc[entry.AllocationId].push(entry);
      return acc;
    }, {});

    // Update employees state with their respective timesheet entries
    setEmployees(prevEmployees => prevEmployees.map(emp => {
      const employeeTimesheet = timesheetsByAllocation[emp.allocationId] || [];
      
      // Map the timesheet entries to the expected format
      const mappedTimesheet = employeeTimesheet.map(entry => ({
        guidId: entry.guidId,
        date: entry.TimesheetDate,
        allocatedHours: entry.AllocationHours,
        workedHours: entry.TimesheetHours,
        approvalStatus: entry.ApprovalStatus,
        holiday: entry.OnHoliday,
        leave: entry.OnLeave,
        comment: entry.TimesheetApproverComments,
        allocationPercentage: entry.AllocationPercentage,
        allocationStatus: entry.AllocationStatus,
      }));

      return { ...emp, timesheet: mappedTimesheet };
    }));
    
  } catch (error) {
    console.error('Error fetching timesheet details:', error);
    toast.error('Error fetching timesheet details for employees.');
  }
};

  // Function to fetch timesheet details for a specific employee
  const fetchTimesheetDetails = async (employeeId) => {
    const employee = employees.find(emp => emp.id === employeeId);
    if (!employee) return;

    try {
      console.log(`Fetching timesheet details for Employee ID: ${employeeId}`);

      // Set loading state
      setEmployees(prevEmployees => prevEmployees.map(emp => 
        emp.id === employeeId ? { ...emp, timesheetLoading: true } : emp
      ));

      const managerEmployeeId = managerDetails.EmployeeId;
      const projectId = selectedProjectId;
      const allocationId = employee.allocationId;
      const selectedDates = getSelectedDates();

      console.log(`Manager Employee ID: ${managerEmployeeId}, Project ID: ${projectId}, Allocation ID: ${allocationId}, Dates: ${selectedDates.join(',')}`);

      const endpoint = `/api/timesheet/approver/${managerEmployeeId}/project/${projectId}/details`;

      const response = await axiosInstance.get(endpoint, {
        params: { 
          allocationId,
          dates: selectedDates.join(',')
        }
      });

      console.log(`Response from timesheet details API:`, response.data);

      if (response.status === 200 && response.data.timesheet) {
        // Map the API response to match component's expected field names
        const mappedTimesheet = response.data.timesheet.map(entry => ({
          guidId: entry.guidId,
          date: entry.TimesheetDate, // Map 'TimesheetDate' to 'date'
          allocatedHours: entry.AllocationHours, // Map 'AllocationHours' to 'allocatedHours'
          workedHours: entry.TimesheetHours, // Map 'TimesheetHours' to 'workedHours'
          approvalStatus: entry.ApprovalStatus,
          holiday: entry.OnHoliday,
          leave: entry.OnLeave,
          comment: entry.TimesheetApproverComments, // Map 'TimesheetApproverComments' to 'comment'
          allocationPercentage: entry.AllocationPercentage,
          allocationStatus: entry.AllocationStatus,
        }));

        // Update the specific employee's timesheet details with mapped data
        setEmployees(prevEmployees => prevEmployees.map(emp => 
          emp.id === employeeId ? { ...emp, timesheet: mappedTimesheet } : emp
        ));
      } else {
        throw new Error("Failed to retrieve timesheet details.");
      }

    } catch (error) {
      console.error('Error fetching timesheet details:', error);
      toast.error(`Error fetching timesheet details: ${error.response?.data?.error || error.message || 'Failed to load timesheet details.'}`);
    } finally {
      // Reset the loading state
      setEmployees(prevEmployees => prevEmployees.map(emp => 
        emp.id === employeeId ? { ...emp, timesheetLoading: false } : emp
      ));
    }
  };

  // Define the refreshData function to reload projects and employees
  const refreshEmployeesData = () => {
    fetchEmployees();
  };


  // Handle week toggle with chevron buttons
  const handleWeekToggle = (direction) => {
    if (dateFilterType === 'week') {
      setWeekOffset((prev) => prev + direction);
    }
  };

  // Handle month toggle buttons
  const handleMonthToggle = (direction) => {
    if (dateFilterType === 'month') {
      setMonthOffset((prev) => prev + direction);
    }
  };

  // Update the getSelectedDates function to handle different filter types
  const getSelectedDates = () => {
    const range = getDateRange();
    if (!range) return [];

    const { startDate, endDate } = range;
    const dates = [];
    let currentDate = new Date(startDate);
    
    while (currentDate <= endDate) {
      dates.push(formatDateStr(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    
    return dates;
  };

  // Update the getDateRange function
  const getDateRange = () => {
    if (dateFilterType === 'day') {
      return getDayDateRange(dayOffset);
    } else if (dateFilterType === 'week') {
      return getWeekDateRange(weekOffset);
    } else if (dateFilterType === 'month') {
      return getMonthDateRange(monthOffset);
    } else if (dateFilterType === 'custom') {
      if (customStartDate && customEndDate) {
        return { startDate: customStartDate, endDate: customEndDate };
      } else {
        return null;
      }
    }
  };

  // Function to get month date range
  const getMonthDateRange = (offset) => {
    const d = new Date();
    d.setMonth(d.getMonth() + offset);
    const startDate = new Date(d.getFullYear(), d.getMonth(), 1);
    const endDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    return { startDate, endDate };
  };

  // Handle filter type change
  const handleFilterTypeChange = (value) => {
    setDateFilterType(value);
    // Reset offsets and dates when filter type changes
    setWeekOffset(0);
    setMonthOffset(0);
    setCustomStartDate(null);
    setCustomEndDate(null);
    setSelectedTimesheets({});
    setFilter('all');
  };

  // Handle custom date selection
  useEffect(() => {
    if (dateFilterType === 'custom' && customStartDate && customEndDate) {
      fetchManagerProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customStartDate, customEndDate, dateFilterType]);

  // Disable Next Week button if weekOffset is >= 0
  const isNextWeekDisabled = weekOffset >= 0;

  // Color scheme for project status
  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'On Hold':
        return 'purple';
      case 'In Progress':
        return 'yellow';
      default:
        return '';
    }
  };

  // Handle accordion click for Employee Table
  const handleAccordionClick = (index) => {
    const employee = finalFilteredEmployees[index];
    if (!employee) return;

    // If expanding the accordion, fetch timesheet details
    if (activeAccordionIndex !== index) {
      fetchTimesheetDetails(employee.id);
    }

    setActiveAccordionIndex(activeAccordionIndex === index ? -1 : index);
  };


  // Handle comment changes in timesheet
  const handleCommentChange = (employeeId, guidId, value) => {
    setTimesheetComments((prev) => ({
      ...prev,
      [employeeId]: {
        ...prev[employeeId],
        [guidId]: value,
      },
    }));
  };

  // Function to get default comment based on action
  const getDefaultComment = (action) => {
    switch (action) {
      case 'Approve':
        return 'Your timesheet for the date has been Approved.';
      case 'Reject':
        return 'Your timesheet for the date has been Rejected.';
      case 'Resubmit':
        return 'Your timesheet should be Resubmitted for the date.';
      default:
        return '';
    }
  };

  // Handle timesheet actions (single)
  const handleTimesheetAction = async (action, employeeId, guidId) => {
    try {
      const comment = timesheetComments[employeeId]?.[guidId] || getDefaultComment(action);
      
      // Prepare payload
      const payload = {
        action,
        guidIds: [guidId], // Single GUIDID in an array
        comments: { [guidId]: comment },
      };

      // API Endpoint: /api/updateTimesheetStatus
      const response = await axiosInstance.post(`/api/updateTimesheetStatus`, payload);

      if (response.data.status) {
        // Refetch timesheet details to reflect updates
        await fetchTimesheetDetails(employeeId);
        toast.success(
          `Timesheet ${
            action === 'Reject' 
              ? 'rejected' 
              : action === 'Resubmit' 
                ? 'resubmitted' 
                : `${action.toLowerCase()}d`
          } successfully.`
        );
                
      } else {
        throw new Error(response.data.message || "Action failed.");
      }
    } catch (error) {
      console.error('Error performing timesheet action:', error);
      toast.error(`Error performing action: ${error.response?.data?.error || error.message || 'Failed to perform action.'}`);
    }
  };

  // Handle bulk employee actions
  const handleBulkEmployeeAction = async (action) => {
    try {
      const selectedEmployeeIds = Object.keys(selectedTimesheets).filter(
        (employeeId) => selectedTimesheets[employeeId]?.size > 0
      );

      if (selectedEmployeeIds.length === 0) {
        toast.warning('No timesheets selected for bulk action.');
        return;
      }

      // Prepare payload
      const payload = {
        action,
        employeeIds: selectedEmployeeIds,
        guidIds: selectedEmployeeIds.reduce((acc, employeeId) => {
          const guidIds = Array.from(selectedTimesheets[employeeId]);
          return [...acc, ...guidIds];
        }, []),
        comments: selectedEmployeeIds.reduce((acc, employeeId) => {
          const guidIds = Array.from(selectedTimesheets[employeeId]);
          const comments = guidIds.reduce((commentAcc, guidId) => {
            const comment = timesheetComments[employeeId]?.[guidId] || getDefaultComment(action);
            return { ...commentAcc, [guidId]: comment };
          }, {});
          return { ...acc, ...comments };
        }, {}),
      };

      // API Endpoint: /api/updateTimesheetStatus
      const response = await axiosInstance.post(`/api/updateTimesheetStatus`, payload);

      if (response.data.status) {
        // Refetch timesheet details for selected employees if their accordion is expanded
        await Promise.all(
          selectedEmployeeIds.map(async (employeeId) => {
            const employeeIndex = employees.findIndex((emp) => emp.id === employeeId);
            if (activeAccordionIndex === employeeIndex) {
              await fetchTimesheetDetails(employeeId);
            }
          })
        );

        // Clear selected timesheets after successful action
        setSelectedTimesheets({});

        toast.success(
          `Timesheets ${
            action === 'Reject'
              ? 'rejected'
              : action === 'Resubmit'
              ? 'resubmitted'
              : `${action.toLowerCase()}d`
          } successfully.`
        );
        fetchEmployees();
      } else {
        throw new Error(response.data.message || 'Bulk action failed.');
      }
    } catch (error) {
      console.error('Error performing bulk employee action:', error);
      toast.error(
        `Error performing bulk action: ${error.response?.data?.error || error.message || 'Failed to perform bulk action.'}`
      );
    }
  };

  // Handle employee actions (single)
  const handleEmployeeAction = async (action, employeeId) => {
    try {
      const guidIds = Array.from(selectedTimesheets[employeeId] || []);

      if (guidIds.length === 0) {
        toast.warning('No timesheets selected for this employee.');
        return;
      }

      // Prepare payload
      const payload = {
        action,
        employeeIds: [employeeId],
        guidIds,
        comments: guidIds.reduce((acc, guidId) => {
          const comment = timesheetComments[employeeId]?.[guidId] || getDefaultComment(action);
          return { ...acc, [guidId]: comment };
        }, {}),
      };

      // API Endpoint: /api/updateTimesheetStatus
      const response = await axiosInstance.post(`/api/updateTimesheetStatus`, payload);

      if (response.data.status) {
        // Refetch timesheet details if the employee's accordion is expanded
        const employeeIndex = employees.findIndex((emp) => emp.id === employeeId);
        if (activeAccordionIndex === employeeIndex) {
          await fetchTimesheetDetails(employeeId);
        }

        // Clear selected timesheets for the employee after successful action
        setSelectedTimesheets((prevSelectedTimesheets) => ({
          ...prevSelectedTimesheets,
          [employeeId]: new Set(),
        }));

        toast.success(
          `Timesheets ${
            action === 'Reject'
              ? 'rejected'
              : action === 'Resubmit'
              ? 'resubmitted'
              : `${action.toLowerCase()}d`
          } successfully.`
        );
        fetchEmployees();
      } else {
        throw new Error(response.data.message || 'Action failed.');
      }
    } catch (error) {
      console.error('Error performing employee action:', error);
      toast.error(
        `Error performing action: ${error.response?.data?.error || error.message || 'Failed to perform action.'}`
      );
    }
  };

  // Render working status label with colors
  const renderWorkingStatusLabel = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return <Label color="green" style={{ borderRadius: "12px" }}>Active</Label>;
      case "in-active":
        return <Label color="orange" style={{ borderRadius: "12px" }}>In-Active</Label>;
      case "closed":
        return <Label color="grey" style={{ borderRadius: "12px" }}>Closed</Label>;
      default:
        return <Label color="grey" style={{ borderRadius: "12px" }}>{status}</Label>;
    }
  };

  // Handle parent checkbox toggle for an employee
  const handleParentCheckbox = (employeeId, checked) => {
    setSelectedTimesheets((prev) => {
      const updated = { ...prev };
      const employee = employees.find(emp => emp.id === employeeId);
      if (!employee) return updated;

      // Get filtered timesheet entries based on current filter
      const filteredTimesheets = employee.timesheet.filter(entry => {
        const statuses = getApprovalStatusesForFilter(filter);
        return statuses ? statuses.includes(entry.approvalStatus) : true;
      });

      if (checked) {
        // Only select timesheets that match the current filter
        updated[employeeId] = new Set(filteredTimesheets.map(entry => entry.guidId));
      } else {
        updated[employeeId] = new Set();
      }
      return updated;
    });
  };

  // Handle child checkbox toggle for a timesheet entry
  const handleChildCheckbox = (employeeId, guidId, checked) => {
    setSelectedTimesheets((prev) => {
      const updated = { ...prev };
      if (!updated[employeeId]) {
        updated[employeeId] = new Set();
      }
      if (checked) {
        updated[employeeId].add(guidId);
      } else {
        updated[employeeId].delete(guidId);
      }
      return updated;
    });
  };

  // Determine available bulk actions based on selected timesheets
  const availableBulkActions = useMemo(() => {
    const actions = new Set();
    let allSubmitted = true;
    let allRejected = true;
    let allApproved = true;

    employees.forEach(employee => {
      const selected = selectedTimesheets[employee.id];
      if (selected) {
        employee.timesheet.forEach(entry => {
          if (selected.has(entry.guidId)) {
            if (entry.approvalStatus !== 1) {
              allSubmitted = false;
            }
            if (entry.approvalStatus !== 3) {
              allRejected = false;
            }
            if (entry.approvalStatus !== 2) {
              allApproved = false;
            }
          }
        });
      }
    });

    if (allSubmitted) {
      actions.add('Approve');
      actions.add('Reject');
    }
    if (allApproved) {
      actions.add('Resubmit');
    }
    if (allRejected) {
      actions.add('Resubmit');
    }

    return Array.from(actions);
  }, [employees, selectedTimesheets]);

  // Update handleBulkAction to consider filtered timesheets
  const handleBulkAction = (action) => {
    const affectedTimesheets = [];

    employees.forEach(employee => {
      const selected = selectedTimesheets[employee.id];
      if (selected) {
        // Filter timesheet entries based on current filter and action requirements
        const filteredTimesheets = employee.timesheet.filter(entry => {
          const statuses = getApprovalStatusesForFilter(filter);
          const matchesFilter = statuses ? statuses.includes(entry.approvalStatus) : true;
          const matchesAction = (
            (action === 'Approve' || action === 'Reject') && entry.approvalStatus === 1 ||
            (action === 'Resubmit') && (entry.approvalStatus === 2 || entry.approvalStatus === 3) // Allow resubmit for both approved and rejected
          );
          return matchesFilter && matchesAction && selected.has(entry.guidId);
        });

        filteredTimesheets.forEach(entry => {
          affectedTimesheets.push({ employeeId: employee.id, guidId: entry.guidId });
        });
      }
    });

    if (affectedTimesheets.length === 0) {
      toast.error('No timesheets available for the selected action.');
      return;
    }

    // Perform the bulk action
    handleBulkEmployeeAction(action, affectedTimesheets);
  };

  // Handle sorting for timesheet tables
  const handleSort = (employeeId, column) => {
    setSortConfig((prev) => {
      const currentSort = prev[employeeId] || { column: null, direction: 'ascending' };
      let direction = 'ascending';
      if (currentSort.column === column && currentSort.direction === 'ascending') {
        direction = 'descending';
      }
      return {
        ...prev,
        [employeeId]: { column, direction },
      };
    });
  };

  // Map filter to approvalStatus
  const getApprovalStatusesForFilter = (filter) => {
    switch (filter) {
      case 'submitted':
        return [1]; // Submitted
      case 'not-submitted':
        return [0]; // Not Submitted
      case 'approved':
        return [2]; // Approved
      case 'rejected':
        return [3]; // Rejected
      default:
        return null; // All
    }
  };

  // Sort timesheet entries based on sortConfig
  const getSortedTimesheet = (employeeId, timesheet) => {
    const config = sortConfig[employeeId];
    if (!config || !config.column) return timesheet;

    const sorted = [...timesheet].sort((a, b) => {
      let aVal = a[config.column];
      let bVal = b[config.column];

      // Handle numeric and date sorting
      if (config.column === 'date') {
        aVal = new Date(aVal);
        bVal = new Date(bVal);
      } else if (['allocatedHours', 'workedHours', 'holiday', 'leave', 'allocationPercentage'].includes(config.column)) {
        aVal = Number(aVal);
        bVal = Number(bVal);
      } else if (config.column === 'allocationStatus') {
        aVal = aVal.toLowerCase();
        bVal = bVal.toLowerCase();
      } else {
        // For string comparison
        aVal = aVal.toString().toLowerCase();
        bVal = bVal.toString().toLowerCase();
      }

      if (aVal < bVal) {
        return config.direction === 'ascending' ? -1 : 1;
      }
      if (aVal > bVal) {
        return config.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    return sorted;
  };

  // Handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setSelectedTimesheets({}); // Reset selections when filter changes
    setActiveAccordionIndex(-1); // Close all accordions when filter changes
  };
  

  const finalFilteredEmployees = useMemo(() => {
    let filtered = employees;
  
    if (selectedEmployees.length > 0) {
      filtered = filtered.filter(employee => selectedEmployees.includes(employee.id));
    }
  
    if (searchQuery.trim() !== '') {
      const lowercasedQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(employee =>
        employee.name.toLowerCase().includes(lowercasedQuery) ||
        employee.email.toLowerCase().includes(lowercasedQuery) ||
        employee.employeeId.toLowerCase().includes(lowercasedQuery)
      );
    }
  
    return filtered;
  }, [employees, selectedEmployees, searchQuery]);
  
  

  // Transform employees data for Dropdown options
  const employeeOptions = useMemo(() => {
    return employees.map(employee => ({
      key: employee.id,
      text: `${employee.name} (${employee.employeeId})`,
      value: employee.id,
    }));
  }, [employees]);

  // Determine available actions at the employee (parent) level
  const getAvailableActionsForEmployee = (employee) => {
    const uniqueStatuses = new Set(employee.timesheet.map(entry => entry.approvalStatus));

    if (uniqueStatuses.size !== 1) {
      // Mixed statuses
      return [];
    }

    const status = [...uniqueStatuses][0];

    switch (status) {
      case 0: // Not Submitted
        return [];
      case 1: // Submitted
        return ['Approve', 'Reject'];
      case 2: // Approved
        return ['Resubmit'];
      case 3: // Rejected
        return ['Resubmit'];
      default:
        return [];
    }
  };

  const selectedClient = employeeData?.clients.find(client => client.ClientID === selectedClientId);

  // Handle range selection
  const handleRangeSelection = (range) => {
    setSelectedRange(range);
    setDateFilterType(range);
    
    // Reset all offsets
    setDayOffset(0);
    setWeekOffset(0);
    setMonthOffset(0);

    // Close the accordion when changing the range
    setActiveAccordionIndex(-1); // Close all accordions

    if (range === 'custom') {
      const defaultRange = getDefaultMonthRange();
      setCustomDateRange(defaultRange);
      handleCustomDateChange(defaultRange);
    } else {
      setCustomDateRange({ startDate: '', endDate: '' });
    }
  };

  const getDefaultMonthRange = () => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    
    return {
      startDate: formatDateForInput(startDate),
      endDate: formatDateForInput(endDate)
    };
  };

  const formatDateForInput = (date) => {
    return date.toISOString().split('T')[0];
  };

  // Add new function to handle custom date changes
  const handleCustomDateChange = (dates) => {
    const { startDate, endDate } = dates;
    if (startDate && endDate) {
      setCustomStartDate(new Date(startDate));
      setCustomEndDate(new Date(endDate));
    }
  };

  // Add new helper function for day range
  const getDayDateRange = (offset) => {
    const today = new Date();
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() + offset);
    return {
      startDate: targetDate,
      endDate: targetDate
    };
  };

  // Add handler for day toggle
  const handleDayToggle = (direction) => {
    if (dateFilterType === 'day') {
      setDayOffset((prev) => prev + direction);
    }
  };

  // 1. Add state variables to control Popup visibility
  const [isDayPopupOpen, setIsDayPopupOpen] = useState(false);
  const [isWeekPopupOpen, setIsWeekPopupOpen] = useState(false);
  const [isMonthPopupOpen, setIsMonthPopupOpen] = useState(false);

  // 2. Handlers to calculate offsets based on selected dates
  const handleDaySelect = (date) => {
    const today = new Date();
    const offset = Math.round((date - today) / (1000 * 60 * 60 * 24));
    setDayOffset(offset);
    setIsDayPopupOpen(false);
  };

  const handleWeekSelect = (date) => {
    const startOfWeek = getStartOfWeek(date);
    const todayStartOfWeek = getStartOfWeek(new Date());
    const diffInWeeks = Math.round((startOfWeek - todayStartOfWeek) / (1000 * 60 * 60 * 24 * 7));
    setWeekOffset(diffInWeeks);
    setIsWeekPopupOpen(false);
  };

  const handleMonthSelect = (date) => {
    const today = new Date();
    const offset = (date.getFullYear() - today.getFullYear()) * 12 + (date.getMonth() - today.getMonth());
    setMonthOffset(offset);
    setIsMonthPopupOpen(false);
  };

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setSearchResults([]);
      return;
    }

    const lowercasedQuery = searchQuery.toLowerCase();
    const results = employees.filter(employee =>
      employee.name.toLowerCase().includes(lowercasedQuery) ||
      employee.email.toLowerCase().includes(lowercasedQuery) ||
      employee.employeeId.toLowerCase().includes(lowercasedQuery)
    ).map(employee => ({
      title: employee.name,
      description: employee.email,
      employeeId: employee.employeeId,
    }));

    setSearchResults(results);
  }, [searchQuery, employees]);

  // Add a function to clear the search filter
  const clearSearchFilter = () => {
    setSearchQuery('');
    setSelectedEmployees([]);
  };

  return (
    <Container fluid>
      <div className="main-layout">
        <div className='right-content'>
          {/* Toast Notifications */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          {/* Greeting Section */}
          <div className='top-content'>
            <div className='greeting'>
              <Header as='h1'>Hello {profile.displayName},</Header>
              <Header as='h2'>{currentDate}</Header>
            </div>
          </div>

          {/* Date Filter Selection */}
          <div style={{ 
            marginBottom: '20px', 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '20px'
          }}>
            {/* Range Type Selection */}
            <Button.Group>
              <Button 
                active={selectedRange === 'day'} 
                onClick={() => handleRangeSelection('day')}
              >
                Day
              </Button>
              <Button 
                active={selectedRange === 'week'} 
                onClick={() => handleRangeSelection('week')}
              >
                Week
              </Button>
              <Button 
                active={selectedRange === 'month'} 
                onClick={() => handleRangeSelection('month')}
              >
                Month
              </Button>
              <Button 
                active={selectedRange === 'custom'} 
                onClick={() => handleRangeSelection('custom')}
              >
                Custom
              </Button>
            </Button.Group>

            {/* Date Controls based on selected range */}
            {selectedRange === 'week' && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '400px' }}>
                <Button
                  icon
                  onClick={() => handleWeekToggle(-1)}
                  disabled={selectedRange !== 'week'}
                >
                  <Icon name="chevron left" />
                </Button>
                <Popup
                  open={isWeekPopupOpen}
                  onClose={() => setIsWeekPopupOpen(false)}
                  trigger={
                    <Header 
                      as="h3" 
                      style={{ margin: '0 15px', flexGrow: 1, textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => setIsWeekPopupOpen(!isWeekPopupOpen)}
                    >
                      {formatDate(getDateRange().startDate)} to {formatDate(getDateRange().endDate)}
                    </Header>
                  }
                  position='bottom center'
                  on='click'
                >
                  <DatePicker
                    selected={getDateRange().startDate}
                    onChange={(date) => handleWeekSelect(date)}
                    inline
                    showWeekNumbers
                  />
                </Popup>
                <Button
                  icon
                  onClick={() => handleWeekToggle(1)}
                  disabled={selectedRange !== 'week' || weekOffset >= 0}
                >
                  <Icon name="chevron right" />
                </Button>
              </div>
            )}

            {selectedRange === 'month' && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '300px' }}>
                <Button
                  icon
                  onClick={() => handleMonthToggle(-1)}
                  disabled={selectedRange !== 'month'}
                >
                  <Icon name="chevron left" />
                </Button>
                <Popup
                  open={isMonthPopupOpen}
                  onClose={() => setIsMonthPopupOpen(false)}
                  trigger={
                    <Header 
                      as="h3" 
                      style={{ margin: '0 15px', flexGrow: 1, textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => setIsMonthPopupOpen(!isMonthPopupOpen)}
                    >
                      {formatMonth(getDateRange().startDate)}
                    </Header>
                  }
                  position='bottom center'
                  on='click'
                >
                  <DatePicker
                    selected={getDateRange().startDate}
                    onChange={(date) => handleMonthSelect(date)}
                    inline
                    showMonthYearPicker
                  />
                </Popup>
                <Button
                  icon
                  onClick={() => handleMonthToggle(1)}
                  disabled={selectedRange !== 'month' || monthOffset >= 0}
                >
                  <Icon name="chevron right" />
                </Button>
              </div>
            )}

            {selectedRange === 'day' && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '250px' }}>
                <Button
                  icon
                  onClick={() => handleDayToggle(-1)}
                  disabled={selectedRange !== 'day'}
                >
                  <Icon name="chevron left" />
                </Button>
                <Popup
                  open={isDayPopupOpen}
                  onClose={() => setIsDayPopupOpen(false)}
                  trigger={
                    <Header 
                      as="h3" 
                      style={{ margin: '0 15px', flexGrow: 1, textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => setIsDayPopupOpen(!isDayPopupOpen)}
                    >
                      {formatDate(getDateRange().startDate)}
                    </Header>
                  }
                  position='bottom center'
                  on='click'
                >
                  <DatePicker
                    selected={getDateRange().startDate}
                    onChange={(date) => handleDaySelect(date)}
                    inline
                  />
                </Popup>
                <Button
                  icon
                  onClick={() => handleDayToggle(1)}
                  disabled={selectedRange !== 'day' || dayOffset >= 0}
                >
                  <Icon name="chevron right" />
                </Button>
              </div>
            )}

            {selectedRange === 'custom' && (
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: '10px',
                marginLeft: 'auto' 
              }}>
                <Input
                  type="date"
                  value={customDateRange.startDate}
                  onChange={(e) => {
                    const newRange = {
                      ...customDateRange,
                      startDate: e.target.value
                    };
                    setCustomDateRange(newRange);
                    handleCustomDateChange(newRange);
                  }}
                  min="2020-01-01"
                  max="2030-12-31"
                  style={{ width: '150px' }}
                />
                <span style={{ margin: '0 5px' }}>-</span>
                <Input
                  type="date"
                  value={customDateRange.endDate}
                  onChange={(e) => {
                    const newRange = {
                      ...customDateRange,
                      endDate: e.target.value
                    };
                    setCustomDateRange(newRange);
                    handleCustomDateChange(newRange);
                  }}
                  min={customDateRange.startDate}
                  max="2030-12-31"
                  style={{ width: '150px' }}
                />
                <Button 
                  onClick={() => {
                    const defaultRange = getDefaultMonthRange();
                    setCustomDateRange(defaultRange);
                    handleCustomDateChange(defaultRange);
                  }}
                >
                  Reset
                </Button>
              </div>
            )}
          </div>

          {/* Display Selected Week Range from API (if backend provides) */}
          {!loading && !error && employeeData && employeeData.weekRange && (
            <Segment>
              <Header as='h4'>
                Selected Week: {formatDate(new Date(employeeData.weekRange.startDate))} to {formatDate(new Date(employeeData.weekRange.endDate))}
              </Header>
            </Segment>
          )}

          {/* Loading Indicator */}
          {loading && (
            <Segment>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
              <div style={{ height: '100px' }}></div> {/* Spacer to accommodate the loader */}
            </Segment>
          )}

          {/* Error Message */}
          {error && (
            <Segment>
              <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{error}</p>
              </Message>
            </Segment>
          )}

          

          {/* Client and Project Selection */}
          {!loading && !error && employeeData && (
            <Grid columns={2} divided className="client-project-container">
              <Grid.Row stretched>
                {/* Client Details - Reduced width */}
                <Grid.Column width={4}>
                  <Segment className="client-details-table" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <Header as='h3'>Client Details</Header>
                    <Table selectable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell style={{ width: '30%' }}>Client ID</Table.HeaderCell>
                          <Table.HeaderCell style={{ width: '70%' }}>Client Name</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {employeeData.clients.map((client) => (
                          <Table.Row
                            key={client.ClientID}
                            onClick={() => {
                              setSelectedClientId(client.ClientID);
                              setSelectedProjectId(null);
                              setTimesheetManualUpload(0); // Reset TimesheetManualUpload
                              setSelectedTimesheets({});
                              setFilter('all'); // Reset filter when client changes
                            }}
                            active={client.ClientID === selectedClientId}
                            style={{ cursor: 'pointer' }}
                          >
                            <Table.Cell>{client.ClientID}</Table.Cell>
                            <Table.Cell>{client.ClientName}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Segment>
                </Grid.Column>

                {/* Project Details - Increased width */}
                <Grid.Column width={12}>
                  <Segment className="project-details-table">
                    <Header as='h3'>
                      Project Details for {selectedClient ? selectedClient.ClientName : 'Select a Client'}
                    </Header>
                    {selectedClient && selectedClient.Projects.length > 0 ? (
                      <Table selectable>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Project ID</Table.HeaderCell>
                            <Table.HeaderCell>Project</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Project Manager</Table.HeaderCell>
                            <Table.HeaderCell>Start Date</Table.HeaderCell>
                            <Table.HeaderCell>End Date</Table.HeaderCell>
                            <Table.HeaderCell>Headcount</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {selectedClient.Projects.map((project) => (
                            <Table.Row
                              key={project.ProjectID}
                              onClick={() => {
                                setSelectedProjectId(project.ProjectID);
                                setTimesheetManualUpload(project.TimesheetManualUpload);
                                setSelectedTimesheets({});
                                setFilter('all'); // Reset filter when client changes
                                setSearchQuery(''); // Clear search query when selecting a new project
                              }}
                              active={project.ProjectID === selectedProjectId}
                              style={{ cursor: 'pointer' }}
                            >
                              <Table.Cell>{project.ProjectID}</Table.Cell>
                              <Table.Cell>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                  {project.ProjectName}
                                  {project.TimesheetManualUpload === 1 && (
                                    <Popup
                                      trigger={
                                        <Icon 
                                          name="edit" 
                                          color="blue" 
                                          size="small"
                                          style={{ marginLeft: '4px' }}
                                        />
                                      }
                                      content="Manual Edit Enabled"
                                      position="top center"
                                      size="tiny"
                                    />
                                  )}
                                </div>
                              </Table.Cell>
                              <Table.Cell>
                                <Label color={getStatusColor(project.ProjectStatus)}>
                                  {project.ProjectStatus}
                                </Label>
                              </Table.Cell>
                              <Table.Cell>{project.ProjectManager}</Table.Cell>
                              <Table.Cell>
                                {project.ProjectStartDate
                                  ? new Date(project.ProjectStartDate).toLocaleDateString()
                                  : '-'}
                              </Table.Cell>
                              <Table.Cell>
                                {project.ProjectEndDate
                                  ? new Date(project.ProjectEndDate).toLocaleDateString()
                                  : '-'}
                              </Table.Cell>
                              <Table.Cell>{project.Headcount}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    ) : (
                      <p>No projects found for this client.</p>
                    )}
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

          {/* Employee Allocation Table or Manual Upload Component */}
          {!loading && !error && selectedProjectId && (
            timesheetManualUpload === 0 ? (
              // Existing Employee Allocation Table with Accordion
              <Segment className="employee-allocation-table" style={{ marginTop: '20px', borderRadius: '12px' }}>
                <Header as='h3'>Employee Allocations for Project ID: {selectedProjectId}</Header>
                <Dropdown
                  placeholder='Select Employees'
                  fluid
                  multiple
                  search
                  selection
                  options={employeeOptions}
                  value={selectedEmployees}
                  onChange={(e, { value }) => setSelectedEmployees(value)}
                  style={{ marginBottom: '10px' }}
                />
                
                {/* Clear All Button - only show if more than one entry is selected */}
                {selectedEmployees.length > 1 && (
                  <Button onClick={clearSearchFilter} color="red" style={{ marginBottom: '5px', marginLeft: 'auto' }}>
                    Clear All
                  </Button>
                )}
                {/* Filter Tabs with Search */}
                <div className="filter-tabs" style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Button.Group>
                    <Button
                      active={filter === 'all'}
                      onClick={() => handleFilterChange('all')}
                    >
                      All
                    </Button>
                    <Button
                      active={filter === 'submitted'}
                      onClick={() => handleFilterChange('submitted')}
                    >
                      Submitted
                    </Button>
                    <Button
                      active={filter === 'not-submitted'}
                      onClick={() => handleFilterChange('not-submitted')}
                    >
                      Not Submitted
                    </Button>
                    <Button
                      active={filter === 'approved'}
                      onClick={() => handleFilterChange('approved')}
                    >
                      Approved
                    </Button>
                    <Button
                      active={filter === 'rejected'}
                      onClick={() => handleFilterChange('rejected')}
                    >
                      Rejected
                    </Button>
                  </Button.Group>
                </div>
                

                <Table celled selectable compact="very" style={{ width: '100%', borderRadius: "12px" }}>
                  <Table.Header>
                    <Table.Row>
                      {/* Keep first column width as is */}
                      <Table.HeaderCell style={{ width: '40px' }}>
                        <Checkbox
                          onChange={(e, { checked }) => {
                            employees.forEach(employee => {
                              handleParentCheckbox(employee.id, checked);
                            });
                          }}
                          title="Select/Deselect All"
                          checked={employees.every(employee => selectedTimesheets[employee.id]?.size === employee.timesheet.length)}
                          indeterminate={
                            employees.some(employee => selectedTimesheets[employee.id]?.size > 0) &&
                            employees.some(employee => selectedTimesheets[employee.id]?.size < employee.timesheet.length)
                          }
                        />
                      </Table.HeaderCell>
                      {/* Adjust other column widths */}
                      <Table.HeaderCell style={{ width: '100px' }}>Employee ID</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '12%' }}>Name</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '18%' }}>Email</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '10%' }}>Role</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '10%' }}>Working Status</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '8%' }}>Allocated Hours</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '8%' }}>Worked Hours</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '8%' }}>Worked %</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '8%' }}>Holidays</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '8%' }}>Leaves</Table.HeaderCell>
                      
                      <Table.HeaderCell style={{ width: '10%' }}>
                            <div>
                                {Object.keys(selectedTimesheets).some(employeeId =>
                                selectedTimesheets[employeeId]?.size > 0
                                ) && (
                                <>
                                    {availableBulkActions.includes('Approve') && (
                                    <Button
                                        color="green"
                                        icon
                                        size="small"
                                        onClick={() => handleBulkAction('Approve')}
                                        title="Approve Selected"
                                        style={{ marginRight: '5px' }}
                                    >
                                        <Icon name="check" style={{ color: 'white' }} />
                                    </Button>
                                    )}
                                    {availableBulkActions.includes('Reject') && (
                                    <Button
                                        color="red"
                                        icon
                                        size="small"
                                        onClick={() => handleBulkAction('Reject')}
                                        title="Reject Selected"
                                        style={{ marginRight: '5px' }}
                                    >
                                        <Icon name="remove" style={{ color: 'white' }} />
                                    </Button>
                                    )}
                                    {availableBulkActions.includes('Resubmit') && (
                                    <Button
                                        color="blue"
                                        icon
                                        size="small"
                                        onClick={() => handleBulkAction('Resubmit')}
                                        title="Resubmit Selected"
                                    >
                                        <Icon name="refresh" style={{ color: 'white' }} />
                                    </Button>
                                    )}
                                </>
                                )}
                            </div>
                        </Table.HeaderCell>

                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {finalFilteredEmployees.length > 0 ? (
                      finalFilteredEmployees.map((employee, index) => {
                        // Determine available actions for the employee
                        const availableActions = getAvailableActionsForEmployee(employee);

                        return (
                          <React.Fragment key={employee.id}>
                            <Table.Row>
                              {/* Parent Checkbox */}
                              <Table.Cell>
                                <Checkbox
                                  checked={
                                    selectedTimesheets[employee.id]?.size === employee.timesheet.filter(entry => {
                                      const statuses = getApprovalStatusesForFilter(filter);
                                      return statuses ? statuses.includes(entry.approvalStatus) : true;
                                    }).length
                                  }
                                  indeterminate={
                                    selectedTimesheets[employee.id]?.size > 0 &&
                                    selectedTimesheets[employee.id]?.size < employee.timesheet.filter(entry => {
                                      const statuses = getApprovalStatusesForFilter(filter);
                                      return statuses ? statuses.includes(entry.approvalStatus) : true;
                                    }).length
                                  }
                                  onChange={(e, { checked }) => handleParentCheckbox(employee.id, checked)}
                                />
                              </Table.Cell>
                              {/* Employee Details */}
                              <Table.Cell>{employee.employeeId}</Table.Cell>
                              <Table.Cell>{employee.name}</Table.Cell>
                              <Table.Cell>{employee.email}</Table.Cell>
                              <Table.Cell>{employee.role}</Table.Cell>
                              <Table.Cell>{renderWorkingStatusLabel(employee.workingStatus)}</Table.Cell>
                              {/* Allocation Metrics */}
                              <Table.Cell>{employee.allocatedHours}</Table.Cell>
                              <Table.Cell>{employee.workedHours}</Table.Cell>
                              <Table.Cell>{employee.workedPercentage}%</Table.Cell>
                              <Table.Cell>{employee.holidays}</Table.Cell>
                              <Table.Cell>{employee.leaves}</Table.Cell>
                              <Table.Cell>
                                {availableActions.map((action) => (
                                  <Button
                                    key={action}
                                    color={
                                      action === 'Approve' ? 'green' :
                                      action === 'Reject' ? 'red' :
                                      action === 'Resubmit' ? 'blue' : 'grey'
                                    }
                                    icon
                                    size="small"
                                    onClick={() => handleEmployeeAction(action, employee.id)}
                                    title={action}
                                    disabled={availableActions.length === 0}
                                    style={{ marginRight: '5px' }}
                                  >
                                    {action === 'Approve' && <Icon name="check" style={{ color: 'white' }} />}
                                    {action === 'Reject' && <Icon name="remove" style={{ color: 'white' }} />}
                                    {action === 'Resubmit' && <Icon name="refresh" style={{ color: 'white' }} />}
                                  </Button>
                                ))}
                              </Table.Cell>
                            </Table.Row>

                            {/* Timesheet Details Accordion */}
                            <Table.Row>
                              <Table.Cell colSpan="13" style={{ padding: "0" }}>
                                <Accordion fluid styled>
                                  <Accordion.Title
                                    active={activeAccordionIndex === index}
                                    index={index}
                                    onClick={() => handleAccordionClick(index)}
                                    style={{ paddingLeft: "1.5em" }}
                                  >
                                    <Icon name="dropdown" />
                                    View Timesheet Details
                                  </Accordion.Title>
                                  <Accordion.Content active={activeAccordionIndex === index}>
                                  {employee.timesheetLoading ? (
                                    <Loader active inline='centered' />
                                  ) : employee.timesheet ? (
                                    <Table celled size="small" compact>
                                      <Table.Header>
                                        <Table.Row>
                                          {/* Child Checkbox Header */}
                                          <Table.HeaderCell style={{ width: '40px' }} />
                                          <Table.HeaderCell
                                            style={{ width: '100px' }}
                                            sorted={
                                              sortConfig[employee.id]?.column === 'allocationId'
                                                ? sortConfig[employee.id].direction
                                                : null
                                            }
                                            onClick={() => handleSort(employee.id, 'allocationId')}
                                          >
                                            Allocation ID
                                          </Table.HeaderCell>
                                          <Table.HeaderCell
                                            sorted={
                                              sortConfig[employee.id]?.column === 'date'
                                                ? sortConfig[employee.id].direction
                                                : null
                                            }
                                            onClick={() => handleSort(employee.id, 'date')}
                                          >
                                            Date
                                          </Table.HeaderCell>
                                          <Table.HeaderCell
                                            sorted={
                                              sortConfig[employee.id]?.column === 'allocatedHours'
                                                ? sortConfig[employee.id].direction
                                                : null
                                            }
                                            onClick={() => handleSort(employee.id, 'allocatedHours')}
                                          >
                                            Allocated Hours
                                          </Table.HeaderCell>
                                          <Table.HeaderCell
                                            sorted={
                                              sortConfig[employee.id]?.column === 'workedHours'
                                                ? sortConfig[employee.id].direction
                                                : null
                                            }
                                            onClick={() => handleSort(employee.id, 'workedHours')}
                                          >
                                            Worked Hours
                                          </Table.HeaderCell>
                                          <Table.HeaderCell
                                            sorted={
                                              sortConfig[employee.id]?.column === 'approvalStatus'
                                                ? sortConfig[employee.id].direction
                                                : null
                                            }
                                            onClick={() => handleSort(employee.id, 'approvalStatus')}
                                          >
                                            Timesheet Status
                                          </Table.HeaderCell>
                                          <Table.HeaderCell
                                            sorted={
                                              sortConfig[employee.id]?.column === 'holiday'
                                                ? sortConfig[employee.id].direction
                                                : null
                                            }
                                            onClick={() => handleSort(employee.id, 'holiday')}
                                          >
                                            Holiday
                                          </Table.HeaderCell>
                                          <Table.HeaderCell
                                            sorted={
                                              sortConfig[employee.id]?.column === 'leave'
                                                ? sortConfig[employee.id].direction
                                                : null
                                            }
                                            onClick={() => handleSort(employee.id, 'leave')}
                                          >
                                            Leave
                                          </Table.HeaderCell>
                                          <Table.HeaderCell>Allocation %</Table.HeaderCell> {/* New Column */}
                                          <Table.HeaderCell>Allocation Status</Table.HeaderCell> {/* New Column */}
                                          <Table.HeaderCell style={{ width: '270px' }}>Comment</Table.HeaderCell>
                                          <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                      </Table.Header>
                                      <Table.Body>
                                        {getSortedTimesheet(employee.id, 
                                          filter === 'all' ? employee.timesheet :
                                          employee.timesheet.filter(entry => {
                                            const statuses = getApprovalStatusesForFilter(filter);
                                            return statuses ? statuses.includes(entry.approvalStatus) : true;
                                          })
                                        ).map((entry) => {
                                          // Determine available actions for the timesheet entry
                                          let entryActions = [];
                                          switch (entry.approvalStatus) {
                                            case 0: // Not Submitted
                                              entryActions = [];
                                              break;
                                            case 1: // Submitted
                                              entryActions = ['Approve', 'Reject'];
                                              break;
                                            case 2: // Approved
                                              entryActions = ['Resubmit'];
                                              break;
                                            case 3: // Rejected
                                              entryActions = ['Resubmit'];
                                              break;
                                            default:
                                              entryActions = [];
                                          }

                                          return (
                                            <Table.Row key={entry.guidId}> {/* Use guidId as key */}
                                              {/* Child Checkbox */}
                                              <Table.Cell>
                                                <Checkbox
                                                  checked={selectedTimesheets[employee.id]?.has(entry.guidId)}
                                                  onChange={(e, { checked }) =>
                                                    handleChildCheckbox(employee.id, entry.guidId, checked)
                                                  }
                                                />
                                              </Table.Cell>
                                              {/* Timesheet Details */}
                                              <Table.Cell>{employee.allocationId}</Table.Cell> {/* Updated to use allocationId */}
                                              <Table.Cell>{formatDate(new Date(entry.date))}</Table.Cell>
                                              <Table.Cell>{entry.allocatedHours}</Table.Cell>
                                              <Table.Cell>{entry.workedHours}</Table.Cell>
                                              <Table.Cell>
                                                <Label color={APPROVAL_STATUS_COLOR[entry.approvalStatus]}>
                                                  {APPROVAL_STATUS[entry.approvalStatus]}
                                                </Label>
                                              </Table.Cell>
                                              <Table.Cell>{entry.holiday}</Table.Cell>
                                              <Table.Cell>{entry.leave}</Table.Cell>
                                              <Table.Cell>{entry.allocationPercentage}%</Table.Cell> {/* Allocation % */}
                                              <Table.Cell>{entry.allocationStatus}</Table.Cell> {/* Allocation Status */}
                                              <Table.Cell>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                  {/* Disabled Comment Input on the left */}
                                                  <Input
                                                    placeholder="Comment"
                                                    value={(timesheetComments[employee.id] && timesheetComments[employee.id][entry.guidId]) || entry.comment || ''}
                                                    readOnly // Disables typing/editing in the input
                                                    style={{
                                                      width: '200px',
                                                      marginRight: '10px', // Adds horizontal spacing between input and popup
                                                    }}
                                                  />

                                                  {/* Conditionally render Popup only if status is "Submitted" */}
                                                  {entry.approvalStatus === 1 ? ( // 1 represents "Submitted" in your status mapping
                                                    <Popup
                                                      trigger={<Button icon="edit" size="small" />}
                                                      on="click"
                                                      position="right center"
                                                      flowing
                                                      hoverable
                                                      style={{ zIndex: 2000 }} // Ensures Popup is on top of other elements
                                                    >
                                                      <Dropdown
                                                        placeholder="Select a reason..."
                                                        selection
                                                        options={[
                                                          { key: 'placeholder', value: null, text: 'Select a reason...', disabled: true },
                                                          ...rejectionReasons
                                                        ]}
                                                        value={selectedRejectionReasons[employee.id]?.[entry.guidId] || null} // Bind current selection
                                                        onChange={(e, { value }) => {
                                                          handleCommentChange(employee.id, entry.guidId, value);
                                                          setSelectedRejectionReasons((prev) => ({
                                                            ...prev,
                                                            [employee.id]: { ...prev[employee.id], [entry.guidId]: value }
                                                          }));
                                                        }}
                                                        clearable // Allows users to clear the selection and reset the dropdown
                                                        openOnFocus
                                                        direction="down"
                                                        pointing="below"
                                                        style={{
                                                          minWidth: '200px',
                                                          zIndex: 1000, // Ensures dropdown menu appears above other content
                                                        }}
                                                        fluid
                                                        selectOnBlur={false} // Prevents dropdown from closing on blur
                                                      />
                                                    </Popup>
                                                  ) : null}
                                                </div>
                                              </Table.Cell>
                                              <Table.Cell>
                                                {entryActions.map((action) => (
                                                  <Button
                                                    key={action}
                                                    color={
                                                      action === 'Approve' ? 'green' :
                                                      action === 'Reject' ? 'red' :
                                                      action === 'Resubmit' ? 'blue' : 'grey'
                                                    }
                                                    icon
                                                    size="small"
                                                    onClick={() => handleTimesheetAction(action, employee.id, entry.guidId)}
                                                    title={action}
                                                    disabled={
                                                      (action === 'Reject' && !(selectedRejectionReasons[employee.id] && selectedRejectionReasons[employee.id][entry.guidId])) || // Enable "Reject" only if a reason is selected
                                                      (action === 'Approve' && selectedRejectionReasons[employee.id] && selectedRejectionReasons[employee.id][entry.guidId]) // Disable "Approve" if a reason is selected
                                                    }
                                                    style={{ marginRight: '5px' }}
                                                  >
                                                    {action === 'Approve' && <Icon name="check" style={{ color: 'white' }} />}
                                                    {action === 'Reject' && <Icon name="remove" style={{ color: 'white' }} />}
                                                    {action === 'Resubmit' && <Icon name="refresh" style={{ color: 'white' }} />}
                                                  </Button>
                                                ))}
                                              </Table.Cell>
                                            </Table.Row>
                                          );
                                        })}
                                      </Table.Body>
                                    </Table>
                                  ) : (
                                    <Message info>
                                      <Message.Header>No Timesheet Details</Message.Header>
                                      <p>Timesheet details are not available for this employee.</p>
                                    </Message>
                                  )}
                                  </Accordion.Content>
                                </Accordion>
                              </Table.Cell>
                            </Table.Row>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell colSpan="13" textAlign="center">
                          No employees found for this project with the selected filter and search criteria.
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Segment>
            ) : (
              // Pass the selected date range to EmployeeAllocationsManualUpload
              <EmployeeAllocationsManualUpload
                employees={finalFilteredEmployees}
                handleBulkEmployeeAction={handleBulkEmployeeAction}
                handleEmployeeAction={handleEmployeeAction}
                timesheetComments={timesheetComments}
                setTimesheetComments={setTimesheetComments}
                selectedTimesheets={selectedTimesheets}
                setSelectedTimesheets={setSelectedTimesheets}
                filter={filter}
                handleFilterChange={handleFilterChange}
                sortConfig={sortConfig}
                handleSort={handleSort}
                handleTimesheetAction={handleTimesheetAction}
                dateRange={getDateRange()}
                onSave={refreshEmployeesData}
                onFetchTimesheetDetails={fetchTimesheetDetails}
              />
            )
          )}
        </div>
      </div>
    </Container>
  );
};

export default ManagerView;
